import { Tk3dConfig } from 'ngx-tk3d';
import { ConfigurationService } from '../../services/configuration.service';

export const Tk3dConfiguration: Tk3dConfig = {
  venue: 'nam-us-10082',
  mapId: 'blockmap',
  flags: {
    seatmap: {
      available_by_default: false,
      user_selection: false,
      max_selection: 60,
      multiple_selection: true
    },
    blockmap: {
      available_by_default: false,
      user_selection: false,
      max_selection: 1,
      multiple_selection: false
    }
  },
  defaultPlugins: ['ThumbnailPlugin'],
  removeUnavailable: {seatmap: true, blockmap: true},
  injectTk3dService: true
};

export function getConfig(configuration: Tk3dConfig, minisiteConfig: ConfigurationService) {
  configuration.venue = minisiteConfig['venueID'];
  configuration.mapId = minisiteConfig['mapID'];
}
