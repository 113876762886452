import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppCommonComponent } from './app-common';
import { AppRoutingModule } from './app-routing';
import { FormsModule } from '@angular/forms';
import { BreadcrumbService, BreadcrumbComponent } from './breadcrumb';
import { LoginComponent } from './login/login.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ContentComponent } from './TK3D/content';
import { PreviewComponent } from './TK3D/minisitePreview/preview.component';
import { CustomComponent } from './TK3D/custom/custom.component';
import { ConfigurationService } from './services/configuration.service';
import { BottomInterfaceComponent } from './TK3D/minisitePreview/bottom-interface/bottom-interface.component';
import { MainInterfaceComponent } from './TK3D/minisitePreview/main-interface/main-interface.component';
import { HeaderInterfaceComponent } from './TK3D/minisitePreview/header-interface/header-interface.component';
import { SearchbarInterfaceComponent } from './TK3D/minisitePreview/header-interface/searchbar-interface/searchbar-interface.component';
import { FiltersInterfaceComponent } from './TK3D/minisitePreview/main-interface/filters-interface/filters-interface.component';
import { MinimapInterfaceComponent } from './TK3D/minisitePreview/main-interface/minimap-interface/minimap-interface.component';
import { TopbarInterfaceComponent } from './TK3D/minisitePreview/topbar-interface/topbar-interface.component';
import { Tk3dService, TK3D_HANDLERS, Tk3dModule, ResizeService } from 'ngx-tk3d';
import { OnLoadMapHandler } from './TK3D/handlers/onloadmap.handler';
import { SeatClickAppHandler } from './TK3D/handlers/seat-click.handler';
import { BlockClickAppHandler } from './TK3D/handlers/block-click.handler';
import { PopoverDestroy } from './TK3D/handlers/popovers-destroy.handler';
import { PopoverCreate } from './TK3D/handlers/popovers-create.handler';
import { SeatmapMiniClickAppHandler } from './TK3D/handlers/seatmap-mini-click.handler';
import { BlockmapMiniClickAppHandler } from './TK3D/handlers/blockmap-mini-click.handler';
import { Tk3dConfiguration } from './TK3D/minisitePreview/preview.configuration';
import { ConfigFilesComponent } from './TK3D/config-files/config-files.component';
import { ResizeNormalService } from './services/resize-normal.service';
import { HomeComponent } from './home/home.component';
import { ModalComponent } from './shared/info-modal/modal.component';
import { SelectComponent } from './home/select/select.component';
import { ContentDVMComponent } from './DVM/content-dvm/content-dvm.component';
import { CustomDVMComponent } from './DVM/custom-dvm/custom-dvm.component';
import { PreviewDVMComponent } from './DVM/preview-dvm/preview-dvm.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { HeaderDvmComponent } from './DVM/preview-dvm/header-dvm/header-dvm.component';
import { TopbarDvmComponent } from './DVM/preview-dvm/topbar-dvm/topbar-dvm.component';
import { InterfaceDvmComponent } from './DVM/preview-dvm/interface-dvm/interface-dvm.component';
import { PreviewDVMService } from './DVM/preview-dvm/preview-dvm.service';
import { FilesDVMComponent } from './DVM/files-dvm/files-dvm.component';


@NgModule({
  declarations: [
    AppComponent,
    AppCommonComponent,
    ContentComponent,
    BreadcrumbComponent,
    LoginComponent,
    ModalComponent,
    PreviewComponent,
    CustomComponent,
    BottomInterfaceComponent,
    MainInterfaceComponent,
    HeaderInterfaceComponent,
    SearchbarInterfaceComponent,
    FiltersInterfaceComponent,
    MinimapInterfaceComponent,
    TopbarInterfaceComponent,
    ConfigFilesComponent,
    HomeComponent,
    SelectComponent,
    ContentDVMComponent,
    CustomDVMComponent,
    PreviewDVMComponent,
    HeaderDvmComponent,
    TopbarDvmComponent,
    InterfaceDvmComponent,
    FilesDVMComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    Tk3dModule.forRoot(Tk3dConfiguration),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    ColorPickerModule
  ],
  providers: [
    Tk3dService,
    ConfigurationService,
    PreviewDVMService,
    {provide: TK3D_HANDLERS, multi: true, useClass: OnLoadMapHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: BlockClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: SeatClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: BlockmapMiniClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: SeatmapMiniClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: PopoverCreate},
    {provide: TK3D_HANDLERS, multi: true, useClass: PopoverDestroy},
    {provide: ResizeService, useClass: ResizeNormalService},
    BreadcrumbService,
  ],
  entryComponents: [ModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
