import { Component, OnInit } from '@angular/core';
import { AuthorizationData } from './authorization-data';
import { StateService } from '@uirouter/angular';
import { HttpClient } from '@angular/common/http';
import * as global from '../services/global-functions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginData: AuthorizationData = {username: '', password: ''};
    errors: boolean;

    constructor(private state: StateService, private http: HttpClient) {
        this.errors = false;
    }

    login() {
        //REAL CASE
        this.http.post(environment.loginUrl, {
            "username":this.loginData.username,
            "password":this.loginData.password,
        }).subscribe( (val) => {
            console.log("POST call successful value returned in body");
            if(val["access_token"]) {
                global.setCookie("access_token", val['access_token'], val['expires_in']);
                this.state.go('common.home');
            } else {
                this.showError();
            }
        },
        response => {
            console.log("POST call in error", response);
        },
        () => {
            console.log("The POST observable is now completed.");
        });
    }

  showError(): void {
    this.errors = true;
  }

  ngOnInit() {
    if (global.getCookie("access_token")) {
      this.state.go('common.home');
    }
  }
}
