import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PreviewDVMService } from '../preview-dvm/preview-dvm.service';
import { StateService } from '@uirouter/angular';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var $:any;


@Component({
  selector: 'app-files-dvm',
  templateUrl: './files-dvm.component.html',
  styleUrls: ['./files-dvm.component.scss']
})
export class FilesDVMComponent implements OnInit {
  filtersCheck: boolean = this.config.configurationFileDVM.filters;
  navigationCheck: boolean = this.config.configurationFileDVM.navigation;
  tabsCheck:boolean = this.config.configurationFileDVM.tabs;
  searchbarCheck:boolean = this.config.configurationFileDVM.searchbar;
  logoCheck:boolean = this.config.configurationFileDVM.logo;
  bgCheck:boolean = this.config.configurationFileDVM.bg;
  faviconCheck:boolean = this.config.configurationFileDVM.favicon;
  name = this.config.configurationFileDVM.minisiteUrl;
  navigationPluginCheck:boolean = this.config.configurationFileDVM.navigationPluginCheck;
  indoorPluginCheck: boolean = this.config.configurationFileDVM.indoorPluginCheck;
  galleryPluginCheck: boolean = this.config.configurationFileDVM.galleryPluginCheck;

  logo: any;
  background: any;

  logoUploaded = false;
  bgUploaded = false;
  favUploaded = false;
  logoUpload: any = { clientDoc: {} };
  bgUpload: any = { clientDoc: {} };
  favUpload: any = { clientDoc: {} };
  logoPreview: any;
  bgPreview: any;
  favPreview:any;

  checkedBg() {
    if($("#bgCheck").prop('checked')) {
        this.bgCheck = true;
    } else {
        this.bgCheck = false;
    }
  }

  checkedFavicon() {
    if($("#faviconCheck").prop('checked')) {
        this.faviconCheck = true;
    } else {
        this.faviconCheck = false;
    }
  }

  checkedLogo() {
    if($("#logoCheck").prop('checked')) {
        this.logoCheck = true;
    } else {
        this.logoCheck = false;
    }
  }

  checkedFilters() {
    if($("#filtersCheck").prop('checked')) {
      this.filtersCheck = true;
      document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
    } else {
      this.filtersCheck = false;
      document.getElementsByClassName("uploadBtn")[0].classList.add("disabled");
    }
  }

  checkedNavigation() {
    if($("#navigationCheck").prop('checked')) {
      this.navigationCheck = true;
      document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
    } else {
      this.navigationCheck = false;
      document.getElementsByClassName("uploadBtn")[1].classList.add("disabled");
    }
  }

  checkedTabs() {
    if($("#tabsCheck").prop('checked')) {
        this.tabsCheck = true;
        document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
    } else {
        this.tabsCheck = false;
        document.getElementsByClassName("uploadBtn")[2].classList.add("disabled");
    }
  } 
  
  checkedSearchbar() {
    if($("#searchbarCheck").prop('checked')) {
        this.searchbarCheck = true;
        document.getElementById("searchbarCheck").classList.remove("disabled");
    } else {
        this.searchbarCheck = false;
        document.getElementById("searchbarCheck").classList.add("disabled");
    }
  } 

  checkedNavigationPlugion(){
    if($("#navigationPluginCheck").prop('checked')) {
        this.navigationPluginCheck = true;
        document.getElementById("navigationPluginCheck").classList.remove("disabled");
    } else {
        this.navigationPluginCheck = false;
        document.getElementById("navigationPluginCheck").classList.add("disabled");
    }
  }

  checkedIndoorPlugin(){
    if($("#indoorPluginCheck").prop('checked')) {
        this.indoorPluginCheck = true;
        document.getElementById("indoorPluginCheck").classList.remove("disabled");
    } else {
        this.indoorPluginCheck = false;
        document.getElementById("indoorPluginCheck").classList.add("disabled");
    }
  }

  checkedGalleryPlugin(){
    if($("#galleryPluginCheck").prop('checked')) {
        this.galleryPluginCheck = true;
        document.getElementById("galleryPluginCheck").classList.remove("disabled");
    } else {
        this.galleryPluginCheck = false;
        document.getElementById("galleryPluginCheck").classList.add("disabled");
    }
  }

  @ViewChild('logo') inputLogo: ElementRef;
  @ViewChild('bg') inputBg: ElementRef;
  @ViewChild('fav') inputFav: ElementRef;

  uploadLogo(event, file) {
    //for upload
    this.logoUpload.clientMandateForm = event.srcElement.files[0];
    this.config.logo = this.logoUpload.clientMandateForm;

    //for preview
    if (file.length === 0)
      return;
  
    var reader = new FileReader();
    reader.readAsDataURL(file[0]); 
    reader.onload = (_event) => { 
      this.config.logoPreview = reader.result; 
      $("#logo-container").attr("src", this.config.logoPreview);
    }

    this.logoUploaded = true;
    $("#logoName").removeClass("hidden");
    $("#logoName").text(file[0].name);
  }

  removeLogo() {
    this.inputLogo.nativeElement.value = null;
    this.logoUploaded = false;
    $("#logoName").addClass("hidden");
    $("#logoName").text("");
    $("#logo-container").attr("src", "");
  }

  uploadFav(event, file) {
    //for upload
    this.favUpload.clientMandateForm = event.srcElement.files[0];
    this.config.favicon = this.favUpload.clientMandateForm;

    //for preview
    if (file.length === 0)
      return;
  
    var reader = new FileReader();
    reader.readAsDataURL(file[0]); 
    reader.onload = (_event) => { 
      this.config.favPreview = reader.result; 
      $("#fav-container").attr("src", this.config.favPreview);
    }

    this.favUploaded = true;
    $("#favName").removeClass("hidden");
    $("#favName").text(file[0].name);
  }

  removeFav() {
    this.inputFav.nativeElement.value = null;
    this.favUploaded = false;
    $("#favName").addClass("hidden");
    $("#favName").text("");
    $("#fav-container").attr("src", "");
  }

  uploadBg(event, file) {
    //for upload
    this.bgUpload.clientMandateForm = event.srcElement.files[0];
    this.config.background = this.bgUpload.clientMandateForm; 

    //for preview
    if (file.length === 0)
      return;
  
    var reader = new FileReader();
    reader.readAsDataURL(file[0]); 
    reader.onload = (_event) => { 
      this.config.bgPreview = reader.result; 
      $("#bg-container").attr("src", this.config.bgPreview);
    }

    this.bgUploaded = true;
    $("#bgName").removeClass("hidden");
    $("#bgName").text(file[0].name);
  }

  removeBg() {
    this.inputBg.nativeElement.value = null;
    this.bgUploaded = false;
    $("#bgName").addClass("hidden");
    $("#bgName").text("");
    $("#bg-container").attr("src", "");
  }

  validateData(){
    if($("#filtersCheck").prop('checked')){
      this.filtersCheck = true;
    } else {
      this.filtersCheck = false;
    }

    if($("#navigationCheck").prop('checked')){
      this.navigationCheck = true;
    } else {
      this.navigationCheck = false;
    }

    if($("#tabsCheck").prop('checked')){
      this.tabsCheck = true;
    } else {
      this.tabsCheck = false;
    }

    if($("#searchbarCheck").prop('checked')){
        this.searchbarCheck = true;
    } else {
        this.searchbarCheck = false;
    }

    if($("#navigationPluginCheck").prop('checked')) {
        this.navigationPluginCheck = true;
    } else {
        this.navigationPluginCheck = false;
    }

    if($("#indoorPluginCheck").prop('checked')) {
        this.indoorPluginCheck = true;
    } else {
        this.indoorPluginCheck = false;
    }

    if($("#galleryPluginCheck").prop('checked')) {
        this.galleryPluginCheck = true;
    } else {
        this.galleryPluginCheck = false;
    }

    this.config.configurationFileDVM.filters = this.filtersCheck;
    this.config.configurationFileDVM.navigation = this.navigationCheck;
    this.config.configurationFileDVM.tabs = this.tabsCheck;
    this.config.configurationFileDVM.searchbar = this.searchbarCheck;
    this.config.configurationFileDVM.logo = this.logoCheck;
    this.config.configurationFileDVM.bg = this.bgCheck;
    this.config.configurationFileDVM.favicon = this.faviconCheck;
    this.config.configurationFileDVM.navigationPluginCheck = this.navigationPluginCheck;
    this.config.configurationFileDVM.indoorPluginCheck = this.indoorPluginCheck;
    this.config.configurationFileDVM.galleryPluginCheck = this.galleryPluginCheck;

    if(this.config.configurationFileDVM.filteringJson && this.config.configurationFileDVM.navigationJson) {
      this.dvm.changeJson(this.config.configurationFileDVM.filteringJson, this.config.configurationFileDVM.navigationJson);
    } else if(this.config.configurationFileDVM.filteringJson) {
      this.config.configurationFileDVM.navigationJson = {outdoor:[]};
      this.dvm.changeJson(this.config.configurationFileDVM.filteringJson, {outdoor:[]});
    } else if(this.config.configurationFileDVM.navigationJson) {
      this.config.configurationFileDVM.filteringJson = {filtering:[]};
      this.dvm.changeJson({filtering:[]}, this.config.configurationFileDVM.navigationJson);
    } else {
      this.config.configurationFileDVM.filteringJson = {filtering:[]};
      this.config.configurationFileDVM.navigationJson = {outdoor:[]};
      this.dvm.changeJson({filtering:[]}, {outdoor:[]});
    }

    this.config.breadcrumbs.push({name:"Preview", state:"common.previewdvm"});
    this.state.go('common.previewdvm');
  }

  filtJson: any = { clientDoc: {} };
  navJson: any = { clientDoc: {} };
  tabJson: any = { clientDoc: {} };
  filteringJson = false;
  navigationJson = false;
  tabsJson = false;

  uploadFile(event, file, key) {
    if(key === "filtering") {
      this.filtJson.clientMandateForm = event.srcElement.files[0];
      this.config.filteringJson = this.filtJson.clientMandateForm; 

    } else if(key === "navigation"){
      this.navJson.clientMandateForm = event.srcElement.files[0];
      this.config.navigationJson = this.navJson.clientMandateForm; 

    } else if(key === "tabs"){
      this.tabJson.clientMandateForm = event.srcElement.files[0];
      this.config.tabOptionsJson = this.tabJson.clientMandateForm; 
    }

    if (file.length === 0)
      return;

    var reader = new FileReader();

    reader.readAsText(file[0]); 
    reader.onload = () => { 
      if(key === "filtering") {
        this.config.filteringJsonPre = reader.result; 
        this.config.filteringJsonPre = JSON.parse(this.config.filteringJsonPre);
        this.filteringJson = true;
        $("#filteringJson").removeClass("hidden");
        $("#filteringJson").text(file[0].name);
        this.config.configurationFileDVM.filteringJson = this.config.filteringJsonPre;

      } else if(key === "navigation"){
        this.config.navigationJsonPre = reader.result;
        this.config.navigationJsonPre = JSON.parse(this.config.navigationJsonPre);
        this.navigationJson = true;
        $("#navigationJson").removeClass("hidden");
        $("#navigationJson").text(file[0].name);
        this.config.configurationFileDVM.navigationJson = this.config.navigationJsonPre;
        
      } else if(key === "tabs") {
        this.config.tabOptionsJsonPre = reader.result;
        this.config.tabOptionsJsonPre = JSON.parse(this.config.tabOptionsJsonPre);
        this.tabsJson = true;
        $("#tabsJson").removeClass("hidden");
        $("#tabsJson").text(file[0].name);
        this.config.configurationFileDVM.tabOptionsJson = this.config.tabOptionsJsonPre;
      }
    }
  }

  // remove files uploaded
  @ViewChild('filterJson') inputFilt: ElementRef;
  @ViewChild('navigation') inputNav: ElementRef;

  removeFile(key) {
    if(key === "filtering") {
      this.inputFilt.nativeElement.value = null;
      this.filteringJson = false;
      $("#filteringJson").addClass("hidden");
      $("#filteringJson").text("");
      this.config.configurationFileDVM.filteringJson = null;

    } else if(key === "navigation"){
      this.inputNav.nativeElement.value = null;
      this.navigationJson = false;
      $("#navigationJson").addClass("hidden");
      $("#navigationJson").text("");
      this.config.configurationFileDVM.navigationJson = null;

    }
  }

  constructor(private config: ConfigurationService, private state: StateService, private dvm: PreviewDVMService) { }

  ngOnInit() {
    this.filtersCheck = this.config.configurationFile.filters;
    this.navigationCheck = this.config.configurationFile.navigation;
    this.searchbarCheck = this.config.configurationFile.searchbar;
    this.tabsCheck = this.config.configurationFile.tabs;

    if(this.filtersCheck) {
      document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
    }

    if(this.navigationCheck) {
      document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
    }

    if(this.tabsCheck) {
      document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
    }

    var name = this.config.configurationFileDVM.minisiteUrl;
    console.log(name)
    if(name) {
      $("#logo-container").attr("src", `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/logo.png`);
      $("#bg-container").attr("src", `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/bg.jpg`);
      $("#fav-container").attr("src", `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.png`);
    }

    if(this.config.configurationFileDVM.filters){
      this.filtersCheck = true
    } else {
      this.filtersCheck = false
    }
  }

}
