import { Component, OnInit, Input, Inject } from '@angular/core';
import { GeneralState, InteractionMessageProtocol, Tk3dService } from 'ngx-tk3d';
import { Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import * as globals from '../../../../services/global-functions';
declare var $:any;

@Component({
  selector: 'app-searchbar-interface',
  templateUrl: './searchbar-interface.component.html',
  styleUrls: ['./searchbar-interface.component.scss']
})
export class SearchbarInterfaceComponent implements OnInit {

  	@Input() state: GeneralState;

	get message(): InteractionMessageProtocol{
		return this.message;
	}
	
	searchTerm$ = new Subject<string>();
	count = 0;

	txtHeader:string = this.config.configurationFile.txtHeader;
	
  	constructor(private tk3d: Tk3dService,
				private config: ConfigurationService) { 
	}

	get results() {
		var selection = [];
		var searchVal = $("#search").val().toString().toLowerCase();

		if (searchVal !== "") {
			var result = [];
			for (var blockID in globals.blockhash) {
				if (blockID.toLowerCase().indexOf(searchVal) !== -1) {
					result.push(blockID);
				}
			}

			for (var i = 0; i < result.length; ++i) {
				var sectionID = result[i];
				var areaID = globals.blockhash[sectionID];
				selection.push(areaID);
			}

			if(this.count === 0){
				this.filterSectionOnBlockmap(selection);
			}

		} else if (this.state.blockmap.moduleRef) {
			try{
				this.filterSectionOnBlockmap([]);
			}catch {}
		}

		return selection;
	}

	locateSeatmap(id){
		this.resetSearch();
		$(".contact-btn").addClass("hidden");
		$(".interior").addClass("hidden");
		$(".interior-mobile").addClass("hidden");

		if(this.config.configurationFile.blocksUnavailable) {

		} else {
			for(var i = 0; i < this.config.configurationFile.exceptions.length; i++) {
				if(this.config.configurationFile.exceptions[i] === id) {
					this.state.blockmap.selected = id;
					this.state.blockmap['size'] = 'mini';
					this.state.seatmap['size'] = 'mini';
					this.state.view3d['size'] = 'large';
					globals.selectedDataWrite(id);
					this.state.view3d.moduleRef['setContainer']('view3d');
					this.state.view3d.moduleRef['load'](id);
					this.state.view3d.visible = true;
					this.state.blockmap.moduleRef.setAvailability(true);
					this.state.blockmap.moduleRef.setElementAvailable(this.state.blockmap.selected);
					this.state.blockmap.moduleRef['disableZoom'](true);
				} else {
					this.state.blockmap.selected = id;
					globals.selectedBlockWrite(id);
					this.state.blockmap['size'] = 'mini';
					this.state.seatmap.visible = true;
					this.state.view3d['size'] = 'mini';
					this.state.blockmap.moduleRef.setAvailability(true);
					this.state.blockmap.moduleRef.setElementAvailable(this.state.blockmap.selected);
					this.state.blockmap.moduleRef['disableZoom'](true);
				}			
			}
		}
	}

	onClick(element) {
		this.locateSeatmap(element);
	}

	mouseEnter(element) {
		this.count = 1;
		this.filterSectionOnBlockmap(element);
	}

	mouseOut() {
		this.count = 0;
	}

	resetSearch(){
		$('.form-group input').blur();
		$('.form-group input').val('');
	}

	filterSectionOnBlockmap(section) {
		this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("filtered"), "filtered");
		this.state.blockmap.moduleRef.addStatus(section, "filtered");
	}

	ngOnInit() {
		if(this.config.configurationFile.buyTickets && !globals.isMobile()) {
			$(".search-bar").css("right", "223px");
		} else {
			$(".search-bar").css("right", "20px");
		}
	}

}
