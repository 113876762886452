import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as global from '../../services/global-functions';
declare var $:any;
import { ConfigurationService } from '../../services/configuration.service';


@Injectable()
export class PopoverCreate extends Handler {
    contentPopover;
    confPopover;
    target;
    imgview = null;

    constructor(private minisite_config: ConfigurationService) {
        super();
        this.handling['blockmap'] = ['mouseenter'];
        this.handling['seatmap'] = ['mouseenter'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if(!global.isMobile()){
            if(message.emmitterSize !== "mini" && message.element.status.includes("available")){
                var id = message.element.id;
                var seating = id.split("-");
    
                if (message.emitter === 'blockmap') {
                    if(this.minisite_config.configurationFile.popupImg === "img") {
                        status.blockmap.moduleRef.getThumbnail(id).then(function(img){
                            $(".ticket-selected-view-img").html("");
                            $(".ticket-selected-view-img").css("background-image", "url("+img.src+")");
                        });
        
                        var section = seating[0].split("_")[1];
                        this.contentPopover = this.getContentBlockImg(section);
                    } else {
                        var section = seating[0].split("_")[1];
                        this.contentPopover = this.getContentBlock(section);
                    }
                    
                } else {
                    var section = seating[0].split("_")[1];
                    var row = seating[1];
                    var seat = seating[2];
    
                    status.seatmap.moduleRef.getThumbnail(id).then(function(img){
                        $(".ticket-selected-view-img").html("");
                        $(".ticket-selected-view-img").css("background-image", "url("+img.src+")");
                    });
                    this.contentPopover = this.getContentSeat(section, row, seat);
                }
                
                this.confPopover = {
                    animation: true,
                    container: 'body',
                    trigger: 'manual',
                    html: true,
                    content: this.contentPopover,
                    placement: 'auto',
                    template: '<div class="popover tk3dpopover bg-header" role="tooltip" style="pointer-events: none;">' +
                    '<h3 class="popover-header"></h3>' +
                    '<div class="popover-body"></div>' +
                    '</div>'
                }

                $(message.element.HTMLElement).popover(this.confPopover);
                let that = this;
                new Promise(function(success){
                    $(message.element.HTMLElement).popover('show');
                    success(that.minisite_config.configurationFile);
                }).then(function(m_config){
                    $(".popover-ticket-lab").css("color", m_config['popoverLab']);
                    $(".popover-ticket-val").css("color", m_config['popoverVal']);
                    $(".popover.bg-header").css("background-color", m_config['bgPopover']);
                });
            }
        }
    }

    public getContentBlock(section) {
        var content = 
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li>"+
                                "<span class='popover-ticket-lab'>Section </span> "+
                                "<span class='popover-ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>";
        return content;
    }

    public getContentBlockImg(section) {
        var content =   "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li>"+
                                "<span class='popover-ticket-lab'>Section </span> "+
                                "<span class='popover-ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>";
        return content;
    }

    public getContentSeat(section, row, seat) {
        var content = 
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li>"+
                            "<span class='popover-ticket-lab'>Section </span> "+
                            "<span class='popover-ticket-val'>"+section+"</span>"+
                        "</li>"+
                        "<li>"+
                            "<span class='popover-ticket-lab'>Row </span> "+
                            "<span class='popover-ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                            "<span class='popover-ticket-lab'>Seat </span> "+
                            "<span class='popover-ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</ul>";
        return content;
    }
}
