import { Injectable } from '@angular/core';
import { TK3DMinisiteConfiguration, DVMMinisiteConfiguration } from '../minisite.configuration';


@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    public minimapModule:any;

    public breadcrumbs = [];
    public configurationFile: TK3DMinisiteConfiguration = {
        flag: "TK3D",
        minisiteUrl: "",
        mapID: "",
        minimapID:"",
        venueID: "",
        venueName: "",
        viewType: "seat",
        viewerIntegration:"old",
        exceptions: [""],
        ticketsUrl: "",
        ticketsName: "",
        hasMinimap: true,
        filters: false,
        navigation: false,
        searchbar: true,
        tabs: false,
        unavailable: false,
        buyTickets: true,
        logo:true,
        bg:true,
        favicon:true,
        popupImg: "img",
        bgHeader: "",
        txtHeader: "",
        bgTopbar:"",
        ticketLab: "",
        ticketVal: "",
        bgPopover: "",
        popoverLab: "",
        popoverVal: "",
        bgButton: "",
        buttonLab: "",
        blockAvailable:"",
        blockAvailableHover:"",
        blockUnavailable:"",
        blockSelected:"",
        blockSelectedHover:"",
        blockOpacity: "",
        seatAvailable:"",
        seatAvailableHover:"",
        seatUnavailable:"",
        seatSelected:"",
        seatSelectedHover:"",
        seatOpacity:""
    }

    public logo: any;
    public background: any;
    public logoPreview: any;
    public bgPreview: any;
    public favicon: any;
    public favPreview: any;

    public tabsCheck: boolean;
    public filtersCheck: boolean;
    public unavailableCheck: boolean;

    public filteringJson: any;
    public seatmapNavigationJson: any;
    public blocksUnavailableJson: any;
    public seatsUnavailableJson: any;
    public navigationJson: any;
    public tabOptionsJson:any;
    
    public filteringJsonPre: any;
    public seatmapNavigationJsonPre: any;
    public blocksUnavailableJsonPre: any;
    public seatsUnavailableJsonPre: any;
    public navigationJsonPre: any;
    public tabOptionsJsonPre: any;

    public configurationFileDVM: DVMMinisiteConfiguration = {
        flag: "DVM",
        integrationType: "",
        libraryVersion:"",
        viewerIntegration:"",
        minisiteUrl: "",
        mapID: "",
        venueID: "",
        minimapID: "",
        venueName: "",
        viewType: "seat",
        hasPano: true,
        hasMinimap: true,
        exceptions: [],
        ticketsUrl: "",
        ticketsName: "",
        filters: false,
        navigation: false,
        tabs: false,
        searchbar: true,
        buyTickets: true,
        logo:true,
        bg:true,
        favicon:true,
        navigationPluginCheck:false,
        indoorPluginCheck:false,
        galleryPluginCheck:false,
        popupImg: "img",
        bgHeader: "",
        txtHeader: "",
        bgTopbar:"",
        ticketLab: "",
        ticketVal: "",
        bgPopover: "",
        popoverLab: "",
        popoverVal: "",
        bgButton: "",
        buttonLab: "",
        blockAvailable:"",
        blockAvailableHover:"",
        blockUnavailable:"",
        seatAvailable:"",
        blockSelected:"",
        blockSelectedHover:"",
        seatAvailableHover:"",
        seatUnavailable:"",
        seatSelected:"",
        seatSelectedHover:""
    }

    constructor() { }

    getMinisiteConfig() {
        return this.configurationFile;
    }
}
