import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { GeneralState, Tk3dService } from 'ngx-tk3d';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StateService } from '@uirouter/core';
import * as global from '../../services/global-functions'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  get status(): GeneralState {
    return this.tk3d.tk3dStatus;
  }

  obj: any = {};

  @Input()
  set id(value) {
    this.status.view3d.selected = value;
    this.status.blockmap.visible = false;
  }
  
  get configuration() {
    var configuration = this.config.configurationFile;
    return configuration;
  }

  uploadMinisite() {
    var st = JSON.stringify(this.config.configurationFile);
    var blob = new Blob([st], {type:'application/json'});
    var file = new File([blob], 'config.json',{type:'application/json'});

    if(this.config.logo) {
        var blob2 = new Blob([this.config.logo], {type:'image/png'});
        var file2 = new File([blob2], "logo.png",{type:'image/png'});
    }

    if(this.config.background) {
        var blob3 = new Blob([this.config.background], {type:'image/jpg'});
        var file3 = new File([blob3], "bg.jpg",{type:'image/jpg'});
    }

    if(this.config.favicon) {
      var blob4 = new Blob([this.config.favicon], {type:'image/png'});
      var file4 = new File([blob4], "favicon.png",{type:'image/png'});
    } else if(!this.config.favicon && this.config.logo){
      var blob4 = new Blob([this.config.logo], {type:'image/png'});
      var file4 = new File([blob4], "favicon.png",{type:'image/png'});
    }

    let formData: FormData = new FormData();
    formData.append('json', file);
    formData.append('client', this.config.configurationFile.minisiteUrl);

    if(file2) {
        formData.append('logo', file2);
    }

    if(file3) {
        formData.append('bg', file3);
    }

    if(file4) {
      formData.append('favicon', file4);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': "Bearer " + global.getCookie('access_token')
      })
    };
     
    return this.http.post(environment.uploadUrl, formData, {responseType:"text", headers:httpOptions.headers})
    .subscribe(
        val => { 
            this.config.breadcrumbs = [];
            this.state.go('common.home');
          },
        error => { console.log(error)}
    );
  }

  constructor( private tk3d: Tk3dService, 
               private config: ConfigurationService,
               private http: HttpClient,
               private state: StateService) { }

  ngOnInit() {
    console.log(this.config)
    if(!global.getCookie('access_token')) {
        this.state.go('common.login');
    }
  }

}
