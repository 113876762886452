import { Component, OnInit, Inject } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { TK3D_CONFIG, Tk3dConfig, Tk3dService } from 'ngx-tk3d';
import { StateService } from '@uirouter/core';
import * as global from '../../services/global-functions'

declare var $: any;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  //config
  minisiteUrl: string = this.config.configurationFile.minisiteUrl;
  venueID: string = this.config.configurationFile.venueID;
  mapID: string = this.config.configurationFile.mapID;
  minimapID: string = this.config.configurationFile.minimapID;
  ticketsUrl: string = this.config.configurationFile.ticketsUrl;
  ticketsName: string = this.config.configurationFile.ticketsName;
  venueName: string = this.config.configurationFile.venueName;
  viewType: string = this.config.configurationFile.viewType;
  exceptions: string[] = this.config.configurationFile.exceptions;
  buyTickets:boolean = this.config.configurationFile.buyTickets;
  popupCheck: string = this.config.configurationFile.popupImg;
  hasMinimap: boolean = this.config.configurationFile.hasMinimap;

  validateForm(form: FormData) {

  }

  checked() {
    if($("#buyTickets").prop('checked')){
      this.buyTickets = true
    } else {
      this.buyTickets = false
    }
  }

  checkedMinimap() {
    if($("#hasMinimap").prop('checked')){
      this.hasMinimap = true
    } else {
      this.hasMinimap = false
    }
  }

  validateData() {
    if(this.exceptions) {
        var toArray = this.exceptions.toString().replace(/\s/g, ",").split(",");
        var removeSpaces = toArray.filter(Boolean)
        this.exceptions = removeSpaces;
    } else {
        this.exceptions = [""];
    }
    
    if($("#section").prop('checked')) {
        this.viewType = "section"
    } else {
        this.viewType = "seat"
    }

    this.config.configurationFile.minisiteUrl = this.minisiteUrl;
    this.config.configurationFile.venueID = this.venueID;
    this.config.configurationFile.minimapID = this.minimapID;
    this.config.configurationFile.mapID = this.mapID;

    if(this.buyTickets) {
      if(this.ticketsUrl.includes("https://")) {
        this.ticketsUrl = this.ticketsUrl;
      } else {
        this.ticketsUrl = "https://"+this.ticketsUrl;
      }

      this.config.configurationFile.ticketsUrl = this.ticketsUrl;
      this.config.configurationFile.ticketsName = this.ticketsName;
      this.config.configurationFile.buyTickets = true;
    } else {
      this.config.configurationFile.buyTickets = false;
    }

    if($("#img").prop('checked')){
      this.popupCheck = "img";
    } else {
      this.popupCheck = "noimg";
    }

    this.config.configurationFile.popupImg = this.popupCheck;

    this.config.configurationFile.venueName = this.venueName;
    this.config.configurationFile.viewType = this.viewType;
    this.config.configurationFile.exceptions = this.exceptions;
    this.config.configurationFile.hasMinimap = this.hasMinimap;

    this.config.configurationFile.flag = "TK3D";

    this.tk3d_config.mapId = this.config.configurationFile['mapID'];
    this.tk3d_config.venue = this.config.configurationFile['venueID'];
    this.tk3d.changeVenue(this.config.configurationFile['venueID']);

    console.log(this.config.configurationFile)

    this.config.breadcrumbs.push({name:"Custom", state:"common.custom"});
    this.state.go('common.custom');
    
  }

  constructor(private config: ConfigurationService, private state: StateService,
    @Inject(TK3D_CONFIG) private tk3d_config: Tk3dConfig, private tk3d: Tk3dService) {
  }

  ngOnInit() {
    console.log(this.config.configurationFile)
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    } else {
      if(this.viewType === 'seat') {
        $("#seat").prop('checked', true);
        $("#section").prop('checked', false);
      } else if(this.viewType === 'section') {
        $("#seat").prop('checked', false);
        $("#section").prop('checked', true);
      }

      if(this.popupCheck === 'img') {
        $("#img").prop('checked', true);
        $("#noImg").prop('checked', false);
      } else if(this.viewType === 'noimg') {
        $("#img").prop('checked', false);
        $("#noImg").prop('checked', true);
      }
    }
  }
}
