import {Injectable} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
declare var $:any;
import * as globalFunctions from '../../services/global-functions';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable()
export class OnLoadMapHandler extends Handler {
    h: number;
    w: number;

    block;
    id: string;

    constructor(private minisite_config: ConfigurationService) {
        super();
        this.handling['blockmap'] = ['loaded'];
        this.handling['seatmap'] = ['loaded'];
        this.handling['view3d'] = ['loaded'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        $(".bg-header").css("background-color", this.minisite_config.configurationFile.bgHeader);
        $(".ext-txt-1").css("color", this.minisite_config.configurationFile.txtHeader);
        $(".ext-txt-2").css("color", this.minisite_config.configurationFile.txtHeader);
        $(".bg-topbar").css("background-color", this.minisite_config.configurationFile.bgTopbar);
        $(".ticket-lab").css("color", this.minisite_config.configurationFile.ticketLab);
        $(".ticket-val").css("color", this.minisite_config.configurationFile.ticketVal);
        $(".rights").css("color", this.minisite_config.configurationFile.txtHeader);
        $(".rights-link").css("color", this.minisite_config.configurationFile.txtHeader);
        $(".mapdata-button").css("background-color", this.minisite_config.configurationFile.bgButton);
        $(".buytickets-lab").css("color", this.minisite_config.configurationFile.buttonLab);
        $(".block.available").css("fill","#0579d8 !important")
        //$("body").css("background-image", "url(https://3ddigitalvenue.com/3dmap/dev/" + this.minisite_config.minisiteUrl + "/bg.jpg)");

        if (message.emmitterSize === 'large') {
            if (message.emitter === 'blockmap') {
                globalFunctions.hideMinimap();
                $(".back-btn").addClass("hidden");
                $(".ticket-selected").addClass("hidden");
                status.blockmap.showLoader = false;
                status.blockmap.moduleRef['setAvailability'](false);

                if(this.minisite_config.configurationFile.blocksUnavailable) {
                    status.blockmap.moduleRef.setElementUnavailable(this.minisite_config.configurationFile.blocksUnavailable);
                }
                status.resizeForce = true;
                var allBlocks = status.blockmap.moduleRef.getAllElements();

                for(var i in allBlocks){
                    var blockID = allBlocks[i].id;
                    globalFunctions.blockhash[blockID] = blockID;
                }

                if(status.view3d.selected) {
                    for(var x = 0; x < this.minisite_config.configurationFile.seatsUnavailable.length; x++) {
                        if(status.view3d.selected !== this.minisite_config.configurationFile.seatsUnavailable[x]){
                            $(".back-btn").removeClass("hidden");
                            $(".ticket-selected").removeClass("hidden");;
                            globalFunctions.selectedBlockWrite((status.view3d.selected).toString().split("-")[0]);
                            globalFunctions.selectedDataWrite((status.view3d.selected).toString());
                            this.h = document.getElementById('main-interface').offsetHeight;
                            document.getElementById('view3d').style.height = this.h + 'px';
                            status.view3d.moduleRef['fixedAspectRatio'](false);
                            status.view3d.moduleRef['responsive'](true);
                            status.resizeForce = true;
                            status.view3d.moduleRef['setContainer']('view3d');
                            status.view3d.moduleRef['load'](status.view3d.selected);
                            status.view3d.visible = true;
                            message.emitter = 'view3d';
                            status.blockmap.size = 'mini';
    
                            status.seatmap.selected = (status.view3d.selected).toString().split("-")[0];
                            status.seatmap.moduleRef['loadMap']((status.view3d.selected).toString().split("-")[0]);                        
                            status.seatmap.size = 'mini';
                            status.resizeForce = true;
                        } else {
                            $("#infoModal").modal();
                        }
                    }
                }
            }

            if (message.emitter === 'seatmap') {
                globalFunctions.showMinimap(this.minisite_config.minimapModule);
                status.seatmap.showLoader = false;
                status.seatmap.moduleRef['setAvailability'](false);
                if(this.minisite_config.configurationFile.seatsUnavailable) {
                    status.seatmap.moduleRef.setElementUnavailable(this.minisite_config.configurationFile.seatsUnavailable);
                }
                status.resizeForce = true;
            }

            this.h = document.getElementById('main-interface').offsetHeight;
            this.w = document.getElementById('main-interface').offsetWidth;
            status.blockmap.moduleRef['setAspectRatio'](this.h / this.w);
            status.seatmap.moduleRef['setAspectRatio'](this.h / this.w);

            if (message.emitter === 'view3d') {
                globalFunctions.hideMinimap();
                this.h = document.getElementById('main-interface').offsetHeight;
                document.getElementById('view3d').style.height = this.h + 'px';
                status.view3d.moduleRef['fixedAspectRatio'](false);
                status.view3d.moduleRef['responsive'](true);
                status.resizeForce = true;
            }
        }

        if (message.emitter === 'view3d' && message.emmitterSize === 'mini'
            && status.seatmap.visible === true && status.seatmap.size === 'large') {
                message.emitter = 'seatmap';
        }
    }
}
