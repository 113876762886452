import { Ng2StateDeclaration } from '@uirouter/angular';
import { AppCommonComponent } from '../app-common';
import { LoginComponent } from '../login/login.component';
import { ContentComponent } from '../TK3D/content';
import { PreviewComponent } from '../TK3D/minisitePreview/preview.component';
import { CustomComponent } from '../TK3D/custom/custom.component';
import { ConfigFilesComponent } from '../TK3D/config-files/config-files.component';
import { HomeComponent } from '../home/home.component';
import { SelectComponent } from '../home/select/select.component';
import { ContentDVMComponent } from '../DVM/content-dvm/content-dvm.component';
import { CustomDVMComponent } from '../DVM/custom-dvm/custom-dvm.component';
import { PreviewDVMComponent } from '../DVM/preview-dvm/preview-dvm.component';
import { FilesDVMComponent } from '../DVM/files-dvm/files-dvm.component';

export const states: Ng2StateDeclaration[] = [
  {
    name: 'common',
    abstract: true,
    component: AppCommonComponent
  },
  {
    name: 'common.login',
    url: '',
    data: {
      openAccess: true
    },

    component: LoginComponent,
  },
  {
    name: 'common.home',
    url: '/home',
    component: HomeComponent
  },
  {
    name: 'common.select',
    url: '/select',
    component: SelectComponent
  },
  {
    name: 'common.content',
    url: '/tk3d/content',
    component: ContentComponent
  },
  {
    name: 'common.preview',
    url: '/tk3d/preview',
    component: PreviewComponent
  },
  {
    name: 'common.custom',
    url: '/tk3d/custom',
    component: CustomComponent
  },
  {
    name: 'common.config',
    url: '/tk3d/config',
    component: ConfigFilesComponent
  },
  {
    name: 'common.contentdvm',
    url: '/dvm/content',
    component: ContentDVMComponent
  },
  {
    name: 'common.customdvm',
    url: '/dvm/custom',
    component: CustomDVMComponent
  },
  {
    name: 'common.filesdvm',
    url: '/dvm/files',
    component: FilesDVMComponent
  },
  {
    name: 'common.previewdvm',
    url: '/dvm/preview',
    component: PreviewDVMComponent
  }
];
