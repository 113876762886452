import { Component, OnInit } from '@angular/core';
import * as global from '../../services/global-functions'
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from '@uirouter/core';
import { PreviewDVMService } from '../preview-dvm/preview-dvm.service';
declare var $: any;

@Component({
  selector: 'app-content-dvm',
  templateUrl: './content-dvm.component.html',
  styleUrls: ['./content-dvm.component.scss']
})
export class ContentDVMComponent implements OnInit {
  //config
  minisiteUrl: string = this.config.configurationFileDVM.minisiteUrl;
  venueID: string = this.config.configurationFileDVM.venueID;
  mapID: string = this.config.configurationFileDVM.mapID;
  minimapID: string = this.config.configurationFileDVM.minimapID;
  ticketsUrl: string = this.config.configurationFileDVM.ticketsUrl;
  ticketsName: string = this.config.configurationFileDVM.ticketsName;
  venueName: string = this.config.configurationFileDVM.venueName;
  viewType: string = this.config.configurationFileDVM.viewType;
  exceptions: string[] = this.config.configurationFileDVM.exceptions;
  buyTickets:boolean = this.config.configurationFileDVM.buyTickets;
  popupCheck: string = this.config.configurationFileDVM.popupImg;
  integrationType:string = this.config.configurationFileDVM.integrationType;
  libraryVersion:string = this.config.configurationFileDVM.libraryVersion;
  viewerIntegration:string = this.config.configurationFileDVM.viewerIntegration;
  hasPano: boolean = this.config.configurationFileDVM.hasPano;
  hasMinimap: boolean = this.config.configurationFileDVM.hasMinimap;

  checked() {
    if($("#buyTickets").prop('checked')){
      this.buyTickets = true
    } else {
      this.buyTickets = false
    }
  }

  checkedMinimap() {
    if($("#hasMinimap").prop('checked')){
      this.hasMinimap = true
    } else {
      this.hasMinimap = false
    }
  }

  validateForm(form: FormData) {

  }

  validateData() {
    if(this.exceptions) {
        var toArray = this.exceptions.toString().replace(/\s/g, ",").split(",");
        var removeSpaces = toArray.filter(Boolean)
        this.exceptions = removeSpaces;
    } else {
        this.exceptions = [];
    }
    
    if($("#section").prop('checked')) {
        this.viewType = "section"
    } else {
        this.viewType = "seat"
    }

    this.config.configurationFileDVM.minisiteUrl = this.minisiteUrl;
    this.config.configurationFileDVM.venueID = this.venueID;
    this.config.configurationFileDVM.mapID = this.mapID;
    this.config.configurationFileDVM.minimapID = this.minimapID;

    if(this.buyTickets) {
      if(this.ticketsUrl.includes("https://")) {
        this.ticketsUrl = this.ticketsUrl;
      } else {
        this.ticketsUrl = "https://"+this.ticketsUrl;
      }

      this.config.configurationFileDVM.ticketsUrl = this.ticketsUrl;
      this.config.configurationFileDVM.ticketsName = this.ticketsName;
      this.config.configurationFileDVM.buyTickets = true;
    } else {
      this.config.configurationFileDVM.buyTickets = false;
    }

    if($("#img").prop('checked')){
      this.popupCheck = "img";
    } else {
      this.popupCheck = "noimg";
    }

    if($("#dvm").prop('checked')){
      this.integrationType = "dvm";
    } else {
      this.integrationType = "bumper";
    }

    if($("#stableLib").prop('checked')){
      this.libraryVersion = "stable";
    } else if($("#latestLib").prop('checked')) {
      this.libraryVersion = "latest";
    } else if($("#devLib").prop('checked')){
      this.libraryVersion = "development";
    }

    if($("#newV").prop('checked')){
      this.viewerIntegration = "new";
    } else {
      this.viewerIntegration = "old";
    }

    if($("#pano").prop('checked')){
      this.hasPano = true;
    } else {
      this.hasPano = false;
    }

    this.config.configurationFileDVM.popupImg = this.popupCheck;
    this.config.configurationFileDVM.venueName = this.venueName;
    this.config.configurationFileDVM.viewType = this.viewType;
    this.config.configurationFileDVM.exceptions = this.exceptions;
    this.config.configurationFileDVM.flag = "DVM";
    this.config.configurationFileDVM.integrationType = this.integrationType;
    this.config.configurationFileDVM.viewerIntegration = this.viewerIntegration;
    this.config.configurationFileDVM.libraryVersion = this.libraryVersion;
    this.config.configurationFileDVM.hasPano = this.hasPano;
    this.config.configurationFileDVM.hasMinimap = this.hasMinimap;

    if(this.hasMinimap) {
      this.dvm.changeVenue(this.venueID, this.mapID, this.minimapID);
    } else {
      this.dvm.changeVenue(this.venueID, this.mapID, null);
    }

    this.config.breadcrumbs.push({name:"Custom", state:"common.customdvm"});
    this.state.go('common.customdvm');
    
  }

  constructor(private config: ConfigurationService, private state: StateService, private dvm: PreviewDVMService) {
  }

  ngOnInit() {
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    } else {
      if(this.viewType === 'seat') {
        $("#seat").prop('checked', true);
        $("#section").prop('checked', false);
      } else if(this.viewType === 'section') {
        $("#seat").prop('checked', false);
        $("#section").prop('checked', true);
      }

      if(this.popupCheck === 'img') {
        $("#img").prop('checked', true);
        $("#noImg").prop('checked', false);
      } else if(this.popupCheck === 'noimg') {
        $("#img").prop('checked', false);
        $("#noImg").prop('checked', true);
      }

      if(this.integrationType === 'dvm') {
        $("#dvm").prop('checked', true);
        $("#bumper").prop('checked', false);
      } else if(this.integrationType === 'bumper') {
        $("#dvm").prop('checked', false);
        $("#bumper").prop('checked', true);
      }

      if(this.viewerIntegration === 'new') {
        $("#newV").prop('checked', true);
        $("#oldV").prop('checked', false);
      } else if(this.viewerIntegration === 'old') {
        $("#newV").prop('checked', false);
        $("#oldV").prop('checked', true);
      }

      if(this.hasPano === true) {
        $("#pano").prop('checked', true);
        $("#noPano").prop('checked', false);
      } else if(this.hasPano === false) {
        $("#pano").prop('checked', false);
        $("#noPano").prop('checked', true);
      }
    }
  }
}
