import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  constructor(private config: ConfigurationService) { }

  setBreadcrumb(lib) {
    if(lib === "dvm") {
      this.config.breadcrumbs.push({name:"Content", state:"common.contentdvm"});
    } else if(lib === "tk3d") {
      this.config.breadcrumbs.push({name:"Content", state:"common.content"});
    }
  }

  ngOnInit() {
  }

}
