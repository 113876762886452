import { Injectable } from '@angular/core';
import { ResizeService, GeneralState } from 'ngx-tk3d';
import * as global from './global-functions';
declare var $:any;

@Injectable({
    providedIn: 'root'
})
export class ResizeNormalService implements ResizeService {
    hm: number;

    headerH: number;
    topbarH: number;
    footerH: number;
    bottomH: number;
    buttonH: number;

    blockmapH: number;
    blockmapW: number;

    container;
    bg;
    svg_ar;
    con_ar;
    h;w;

    moduleRef: any;

    constructor() { }

    handleResize(status: GeneralState) {
        if(!global.isMobile() && window.location.href.includes("preview")){
            var ar = global.getSize("blockmap");
            var nh = window.innerHeight;
            var wd;
            $(".container").css("min-width","700px");

            wd = (nh - $("#header-wrap").innerHeight() - $("#topbar").innerHeight() - $(".rights").innerHeight() - 1)*(1200/680);

            $("#interface-wrap").css("max-width", wd);
            $("#header-wrap").css("max-width", wd);
            $(".rights").css("max-width", wd);
            $(".rights").css("position","relative");
            status.blockmap.moduleRef.setAspectRatio(ar);

            $("#view3d").height($("#main-interface").height());
            status.view3d.moduleRef.fixedAspectRatio(false);
            status.view3d.moduleRef.responsive(true);

            // if(status.blockmap.size === 'mini'){
            //     const h = document.getElementById('minimap').offsetHeight;
            //     const w = document.getElementById('minimap').offsetWidth;
            //     status.blockmap.moduleRef['setAspectRatio'](h/w);
            // }
        }    

        if (global.isMobile() && window.innerWidth < 992) {
            $('#zoom_buttons_container').addClass('hidden');
            try {
                this.getHeight(status);
            } catch (e) {

            }
        }

        if(global.isMobile()){
            $('.interface').height(0);
            $("#search").attr("placeholder", "Enter section nº");
        } else {
            $("#search").attr("placeholder", "Enter section number");
        }
    }

    public getHeight(status) {
        if (!status.blockmap.moduleRef['getMapId']()) {
            return; 
        }
        var hh = 0;
        $("#zoom_buttons_container").addClass("hidden");

        $('.interface').height(0);

        hh = window.innerHeight - ($("#topbar").height() + $("#header-wrap").height()
        + $(".rights").height());

        $('.interface').height(0);
        $(".rights").css("position","absolute");
        $(".rights").css("bottom","0");
        $(".rights").css("width","100%");

        if(status.view3d.visible === true) {
            $("#main-interface").height(hh);
            $('#seatmap').height(0);
            $('#view3d').height($('#main-interface').height());
        } else if(status.blockmap.visible === true && status.blockmap.size === 'large'){
            $('#seatmap').height(0);
            $("#main-interface").height(hh);
            $('#blockmap').height($('#main-interface').height());
            var min_zoom = global.getMinZoom("blockmap");
            if(min_zoom){
                status.blockmap.moduleRef.setAspectRatio($('#blockmap').height()/$('#blockmap').width());
                status.blockmap.moduleRef.setMinZoom(min_zoom);
            }
        } else if(status.seatmap.visible === true && status.seatmap.size === 'large'){
            $('#blockmap').height(0);
            $("#main-interface").height(hh);
            $('#seatmap').height($('#main-interface').height());
            var min_zoom = global.getMinZoom("seatmap");
            if(min_zoom){
                status.seatmap.moduleRef.setAspectRatio($('#seatmap').height()/$('#seatmap').width());
                status.seatmap.moduleRef.setMinZoom(min_zoom);
            }
        } 
    }
}
