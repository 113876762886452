import { Component, OnInit, Input, Inject } from '@angular/core';
import { Tk3dService, TK3D_CONFIG, Tk3dConfig } from 'ngx-tk3d';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var $:any;

@Component({
  selector: 'app-topbar-interface',
  templateUrl: './topbar-interface.component.html',
  styleUrls: ['./topbar-interface.component.scss']
})
export class TopbarInterfaceComponent implements OnInit {

    @Input() status;
    filters: boolean = this.config.configurationFile.filters;
    tabs: boolean = this.config.configurationFile.tabs;
    
    public filteringDisplay(event) {
        var element = event.currentTarget;
        let action = $(element).attr("data-action");
        if(action === 'open'){
            this.menuOpen();
        }else{
            this.menuClose();
        }
        return null;
    }

    menuOpen(){
        $("#menu").attr("data-status","opened");
        $(".filt-btn").attr("data-action","close");
        return null;  
    }

    menuClose(){
        $("#menu").attr("data-status","closed");
        $(".filt-btn").attr("data-action","open");
        $(".filt-btn").find("i").removeClass("icon-arrow-down").addClass("icon-menu");
        return null;
    }

    public isSectionHidden() {
        var sectionH;

        if(this.status.blockmap.visible === true && this.status.blockmap.size === 'large') {
            sectionH = true;
        } else if (this.status.seatmap.visible === true || this.status.view3d.visible === true) {
            sectionH = false;
        }

        return sectionH;
    }

    public isRowSeatHidden() {
        var rowSeatH;

        if(this.status.blockmap.visible === true && this.status.blockmap.size === 'large') {
            rowSeatH = true;
        } else if (this.status.seatmap.visible === true && this.status.seatmap.size === 'large') {
            rowSeatH = true;
        } else if (this.status.view3d.visible === true) {
            if(this.config.configurationFile.viewType === "seat") {
                if(this.config.configurationFile.exceptions && this.config.configurationFile.exceptions.indexOf(this.status.blockmap.selected) > -1) {
                    rowSeatH = true;
                } else {
                    rowSeatH = false;
                }
            } else {
                if(this.config.configurationFile.exceptions && this.config.configurationFile.exceptions.indexOf(this.status.blockmap.selected) > -1) {
                    rowSeatH = false;
                } else {
                    rowSeatH = true;
                }
            }
        }

        return rowSeatH;
    }

    get options(){
        var option = [];

        for(var i in this.config.tabOptionsJsonPre) {
        option.push(this.config.tabOptionsJsonPre[i]);
        }

        return option;
    }

    public openOption(option, index) {
        if(!$("#option-button-"+index).hasClass("active")){
            $(".mapstyle-button").removeClass("unactive");
            $(".mapstyle-button").removeClass("active");
            $("#option-button-"+index).addClass("active");
        }
        $(".ticket-selected").addClass("hidden");
        this.tk3d_config.venue = option.venueid;
        this.tk3d_config.mapId = option.mapid;
        this.tk3d.changeVenue(option.venueid);
    }

    constructor(private tk3d: Tk3dService, @Inject(TK3D_CONFIG) private tk3d_config: Tk3dConfig, 
    private config: ConfigurationService) { 
        this.filters = this.config.configurationFile.filters;
        this.tabs = this.config.configurationFile.tabs;
    }

    ngOnInit() {
        
    }
}
