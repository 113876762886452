import { Component, OnInit, Input } from '@angular/core';
import { GeneralState } from 'ngx-tk3d';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-header-interface',
  templateUrl: './header-interface.component.html',
  styleUrls: ['./header-interface.component.scss']
})
export class HeaderInterfaceComponent implements OnInit {

  @Input() state: GeneralState;

  search = this.config.configurationFile.searchbar;
  venueName = this.config.configurationFile.venueName;
  imgUrl = this.config.configurationFile.minisiteUrl;
  imgPreview = this.config.logoPreview;
  logoUrl:any;
  buyTickets = this.config.configurationFile.buyTickets;

   get image() {
     this.logoUrl = this.imgPreview ? this.imgPreview : `https://3ddigitalvenue.com/3dmap/pro-sites/${this.imgUrl}/logo.png`;
     return this.logoUrl;
   }

  constructor(private config: ConfigurationService) { }

  ngOnInit() {
  }

}
