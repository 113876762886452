import { Component, OnInit, Input, Inject } from '@angular/core';
import { GeneralState, Tk3dService } from 'ngx-tk3d';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var $:any;

@Component({
  selector: 'app-filters-interface',
  templateUrl: './filters-interface.component.html',
  styleUrls: ['./filters-interface.component.scss']
})
export class FiltersInterfaceComponent implements OnInit {

    @Input() state: GeneralState;

    get status(): GeneralState {
        return this.tk3d.tk3dStatus;
    }

    constructor( private tk3d: Tk3dService,
        private config: ConfigurationService) {
    }

    subfilterList = false;

    // get items() {
    //     var item = [];
    //     if(this.config.configurationFile.filtersList) {
    //         const a = this.config.configurationFile.filtersList;
        
    //         for(let i = 0; i < a.length; i++) {
    //             item.push(a[i]);
    //         }
    //     }

    //     return item;
    // }

    // get subitems() {
    //     var subitems = [];
    //     if(this.config.configurationFile.filtersList) {
    //         const a = this.config.configurationFile.filtersList;
        
    //         for(var i = 0; i < a.length; i++) {
    //             if(a[i].subfilterName) {
    //                 if(a[i].subfilterName.length > 0) {
    //                     for(var j = 0; j < a[i].subfilterName.length; j++) {
    //                         if(a[i].subfilterName[j]){
    //                             subitems.push(a[i].subfilterName[j]);
    //                         }
    //                     }
    //                 } else {
    //                     delete a[i].subfilterName;
    //                 }
    //             } 
    //         }
    //     }

    //     return subitems;
    // }

    public showChildrens(event) {
        let accordion_btn = event.currentTarget;
        var i = accordion_btn.querySelector("i");

        if(i.classList.contains("ti-angle-right")){
            i.classList.remove("ti-angle-right");
            i.classList.add("ti-angle-down");
        }else{
            i.classList.remove("ti-angle-down");
            i.classList.add("ti-angle-right");
        }

        var dropItem = $(accordion_btn).next(".accordion");
        $(accordion_btn).toggleClass("active");
        
        if ($(accordion_btn).hasClass("active")) {
            $(dropItem).attr("style","height: auto");
        } else {
            $(dropItem).removeAttr("style");
        };
    }

    interface = {
        current:"topview",
        seatmap:{
            nodes_selected: []
        },
        topview:{
            nodes_selected: []
        },
        filtering:{
            ref : null,
            nodes : {
                sections: [],
                rows: [],
                seats: []
            }
        },
        navigation:{
            to: "",
            from: ""
        },
        window:{
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            //animation: requestAnimationFrame(this.windowLoop)
        }
    };

    interfaceResetFiltering(){
        this.interface.filtering = {
            ref : null,
            nodes : {
                sections: [],
                rows: [],
                seats: []
            }
        }
    }

    removeOldFiltering(){
        //remove old filtering
        $(".filter").removeClass("active");
        //var nodes = this.viewer.getNodesByTag("section","vipFilter");
        //nodes = nodes.concat(this.viewer.getNodesByTag("section","regFilter"));
        this.status.blockmap.moduleRef.setAvailability(false);
        $("#top-guide").removeClass("filtered");
        $(".section-lab").html("Section");

        return null;
    }

    filtering_referenced = {};
    filtering_typereferenced = {};

    filterBy(event){    
        var element = event.currentTarget;
        this.interfaceResetFiltering();
        if(!$(element).hasClass("active")){
            this.removeOldFiltering();
            $(".filter").find("button").removeClass("active");
            var ref = $(element).attr("id");
            $(element).addClass("active");
            var nodes = this.filtering_referenced[ref].nodes;
            this.state.blockmap.moduleRef.setAvailability(nodes.sections, false);
            this.interface.topview.nodes_selected = nodes.sections;
            this.interface.filtering.nodes = nodes;
            this.interface.filtering.ref = ref;
            $("#top-guide .section").addClass("d-none");
        }else{
            $(".filter").find("button").removeClass("active");
            this.removeOldFiltering();
        }

        return null;
    }

    selected = [];

    removeString(elements,chars){
        var resp = [];
        for(var i=0;i<elements.length;i++){
            resp.push(elements[i].replace(chars, ""));
        }
        return resp;
    }

    filteringToggleSubList(element){
        var that = this;
        if($(element).attr("data-status") == 'closed'){
            $(".filter-item[data-status='visible']").each(function(e){
                var opened_element = $(this);
                that.filteringCloseSubList(opened_element);
            });
            this.filteringOpenSubList(element);
        }else{
            this.filteringCloseSubList(element);
        }
        return null;
    }

    filteringOpenSubList(element){
        $(".filter-item[data-status='closed']").removeClass("active");

        $(".filter-item[data-status='closed']").each(function(e){
            var b = $(this);
            var s = $(b).siblings();
            $(s).each(function(){
                var sl = $(this);
                var a = $(sl).find(".filter-subitem.active");
                if (a.length>0){
                    $(b).addClass("active");
                }
            })
        });
        
        $(element).addClass("active");

        var i = $(element).find(".fa");
        $(i).removeClass("fa-angle-right").addClass("fa-angle-down");
        $(i).css("color", "#fff");

        var sublist = $(element).siblings(".filter-subitem-list");
        $(sublist).removeClass("hidden");
        $(element).attr("data-status",'visible');

        var not_scroll = ["ps1_sf",'lx_sf','ps2_sf'];
        if ($.inArray($(sublist).attr("id"),not_scroll)==-1){
            $(".filtering-list").animate({ scrollTop: $(sublist).prop("scrollHeight")*2 }, 1000);
        }
        return null;
    }

    filteringCloseSubList(element){
        var i = $(element).find(".fa");
                $(i).removeClass("fa-angle-down").addClass("fa-angle-right");
                $(i).css("color", "#a5a5a5");

        var sublist = $(element).siblings(".filter-subitem-list");

        $(sublist).addClass("hidden");
        $(element).attr("data-status",'closed');
        return null;
    }

    filteringItemConstruct(itemRef) {
        this.addItemRef(itemRef, null);
    }

    addItemRef(item,parentItem){
        item.ref = item.title.replace(/[^a-zA-Z0-9]/g, "").replace(/ /g,'').toLowerCase();
        if(parentItem){
            if (parentItem.ref){
                item.parentref = parentItem.ref;
            }
            if (parentItem.type){
                item.parenttype = parentItem.type;
            }
        }
        this.filtering_referenced[item.ref]=item;
        if (item.type && !this.filtering_typereferenced[item.type]){
            this.filtering_typereferenced[item.type] = [];
        }
        if (item.parenttype && !this.filtering_typereferenced[item.parenttype]){
            this.filtering_typereferenced[item.parenttype] = [];
        }

        if(item.children){
            for(var i =0;i<item.children.length;i++){
                item.children[i] = this.addItemRef(item.children[i],item);
            }
        }else if(item.nodes){
            var nodes_toadd = [];
            nodes_toadd = nodes_toadd.concat(item.nodes.sections);
            nodes_toadd = nodes_toadd.concat(item.nodes.rows);
            nodes_toadd = nodes_toadd.concat(item.nodes.seats);
            if(item.type){
                this.filtering_typereferenced[item.type] = this.filtering_typereferenced[item.type].concat(nodes_toadd);
            }else{
                this.filtering_typereferenced[item.parenttype] = this.filtering_typereferenced[item.parenttype].concat(nodes_toadd);
            }
        }
        
        return item;
    }

    get items() {
        return this.item;
    }

    get childrens() {
        return this.child;
    }

    item = [];
    child = [];

    ngOnInit() {
        if(this.config.filteringJsonPre) {
            for(var i = 0; i < this.config.filteringJsonPre.filtering.length; i++) {
                var itemRef = this.config.filteringJsonPre.filtering[i];
                this.filteringItemConstruct(itemRef);
                this.item.push(itemRef);
                if(itemRef.children) {
                    for(var j = 0; j < itemRef.children.length; j++) {
                        this.child.push(itemRef.children[j])
                    }
                }
            }
        }
    }
}
