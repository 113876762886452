import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from './breadcrumb.service';
import {BreadcrumbModel} from './breadcrumb.model';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  constructor(private config: ConfigurationService) {

  }

  get breadcrumbs() {
    return this.config.breadcrumbs;
  }

  removeState(b) {
    if(b < 0) {
      this.config.breadcrumbs = [];
    } else {
      this.config.breadcrumbs.splice(b+1);
    }
  }

  ngOnInit() {
  
  }


}
