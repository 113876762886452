import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ConfigurationService } from '../../services/configuration.service';
import { StateService } from '@uirouter/core';
import * as global from '../../services/global-functions'
declare var $: any;


@Component({
  selector: 'app-config-files',
  templateUrl: './config-files.component.html',
  styleUrls: ['./config-files.component.scss']
})
export class ConfigFilesComponent implements OnInit {

    name = this.config.configurationFile.minisiteUrl;

    constructor(private config: ConfigurationService, private modalService: BsModalService,
        private state: StateService) { }

    modalRef: BsModalRef;
    modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: ' modal-dialog-centered'
    };
    
    //
    filtersCheck: boolean = this.config.configurationFile.filters;
    navigationCheck: boolean = this.config.configurationFile.navigation;
    searchbarCheck: boolean = this.config.configurationFile.searchbar;
    unavailableCheck: boolean = this.config.configurationFile.unavailable;
    tabsCheck: boolean = this.config.configurationFile.tabs;
    logoCheck:boolean = this.config.configurationFile.logo;
    bgCheck:boolean = this.config.configurationFile.bg;
    faviconCheck:boolean = this.config.configurationFile.favicon;
    
    // filters array & values
    filters = [];

    // tabs array & values
    options = [];
    venue: string;
    map: string;

    logoUploaded = false;
    bgUploaded = false;
    favUploaded = false;
    logoUpload: any = { clientDoc: {} };
    bgUpload: any = { clientDoc: {} };
    favUpload: any = { clientDoc: {} };
    logoPreview: any;
    bgPreview: any;
    favPreview:any;

    @ViewChild('logo') inputLogo: ElementRef;
    @ViewChild('bg') inputBg: ElementRef;
    @ViewChild('fav') inputFav: ElementRef;

    checkedLogo() {
        if($("#logoCheck").prop('checked')) {
            this.logoCheck = true;
        } else {
            this.logoCheck = false;
        }
    }

    uploadLogo(event, file) {    
        //for upload
        this.logoUpload.clientMandateForm = event.srcElement.files[0];
        this.config.logo = this.logoUpload.clientMandateForm; 

        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
        this.config.logoPreview = reader.result; 
        $("#logo-container").attr("src", this.config.logoPreview);
        }

        this.logoUploaded = true;
        $("#logoName").removeClass("hidden");
        $("#logoName").text(file[0].name);
    }

    removeLogo() {
        this.inputLogo.nativeElement.value = null;
        this.logoUploaded = false;
        $("#logoName").addClass("hidden");
        $("#logoName").text("");
        $("#logo-container").attr("src", "");
    }

    checkedFavicon() {
        if($("#faviconCheck").prop('checked')) {
            this.faviconCheck = true;
        } else {
            this.faviconCheck = false;
        }
    }

    uploadFav(event, file) {
        //for upload
        this.favUpload.clientMandateForm = event.srcElement.files[0];
        this.config.favicon = this.favUpload.clientMandateForm;

        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
        this.config.favPreview = reader.result; 
        $("#fav-container").attr("src", this.config.favPreview);
        }

        this.favUploaded = true;
        $("#favName").removeClass("hidden");
        $("#favName").text(file[0].name);
    }

    removeFav() {
        this.inputFav.nativeElement.value = null;
        this.favUploaded = false;
        $("#favName").addClass("hidden");
        $("#favName").text("");
        $("#fav-container").attr("src", "");
    }

    checkedBg() {
        if($("#bgCheck").prop('checked')) {
            this.bgCheck = true;
        } else {
            this.bgCheck = false;
        }
    }

    uploadBg(event, file) {
        //for upload
        this.bgUpload.clientMandateForm = event.srcElement.files[0];
        this.config.background = this.bgUpload.clientMandateForm; 

        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
        this.config.bgPreview = reader.result; 
        $("#bg-container").attr("src", this.config.bgPreview);
        }

        this.bgUploaded = true;
        $("#bgName").removeClass("hidden");
        $("#bgName").text(file[0].name);
    }

    removeBg() {
        this.inputBg.nativeElement.value = null;
        this.bgUploaded = false;
        $("#bgName").addClass("hidden");
        $("#bgName").text("");
        $("#bg-container").attr("src", "");

    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.modalConfig);
    }

    // upload files
    // read files for upload
    filtJson: any = { clientDoc: {} };
    navJson: any = { clientDoc: {} };
    unavailableJson: any = { clientDoc: {} };
    tabsOptJson: any = { clientDoc: {} };

    //ngModal // read files for use in preview and modal
    isFilteringJson = false;
    isNavigationJson = false;
    isUnavailableJson = false;
    isTabsJson = false;

    checkedSearchbar() {
        if($("#searchbarCheck").prop('checked')) {
            this.searchbarCheck = true;
            document.getElementById("searchbarCheck").classList.remove("disabled");
        } else {
            this.searchbarCheck = false;
            document.getElementById("searchbarCheck").classList.add("disabled");
        }
    } 

    checkedFilters() {
        if($("#filtersCheck").prop('checked')) {
          this.filtersCheck = true;
          document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
        } else {
          this.filtersCheck = false;
          document.getElementsByClassName("uploadBtn")[0].classList.add("disabled");
        }
    }
    
    checkedNavigation() {
        if($("#navigationCheck").prop('checked')) {
            this.navigationCheck = true;
            document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
        } else {
            this.navigationCheck = false;
            document.getElementsByClassName("uploadBtn")[1].classList.add("disabled");
        }
    }

    checkedTabs() {
        if($("#tabsCheck").prop('checked')) {
            this.tabsCheck = true;
            document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
        } else {
            this.tabsCheck = false;
            document.getElementsByClassName("uploadBtn")[2].classList.add("disabled");
        }
    }

    checkedUnavailable() {
        if($("#unavailableCheck").prop('checked')) {
            this.unavailableCheck = true;
            document.getElementsByClassName("uploadBtn")[3].classList.remove("disabled");
        } else {
            this.unavailableCheck = false;
            document.getElementsByClassName("uploadBtn")[3].classList.add("disabled");
        }
    }

    // remove files uploaded
    @ViewChild('filterJson') inputFilt: ElementRef;
    @ViewChild('navigationJson') inputNavigation: ElementRef;
    @ViewChild('blocks') inputUnavailable: ElementRef;
    @ViewChild('tabs') inputTabs: ElementRef;

    uploadFile(event, file, key) {
        if(key === "filtering") {
            this.filtJson.clientMandateForm = event.srcElement.files[0];
            this.config.filteringJson = this.filtJson.clientMandateForm; 
        } else if(key === "navigation"){
            this.navJson.clientMandateForm = event.srcElement.files[0];
            this.config.navigationJson = this.navJson.clientMandateForm; 
        }else if(key === "unavailable") {
            this.unavailableJson.clientMandateForm = event.srcElement.files[0];
            this.config.blocksUnavailableJson = this.unavailableJson.clientMandateForm; 
        } else if(key === "tabs") {
            this.tabsOptJson.clientMandateForm = event.srcElement.files[0];
            this.config.tabOptionsJson = this.tabsOptJson.clientMandateForm;
        }

        if (file.length === 0)
        return;

        var reader = new FileReader();

        reader.readAsText(file[0]); 
        reader.onload = () => { 
            if(key === "filtering") {
                this.config.filteringJsonPre = reader.result; 
                this.config.filteringJsonPre = JSON.parse(this.config.filteringJsonPre);
                this.isFilteringJson = true;
                $("#filteringJson").removeClass("hidden");
                $("#filteringJson").text(file[0].name);
                this.config.configurationFile.filteringJson = this.config.filteringJsonPre;
            } else if(key === "navigation"){
                this.config.navigationJsonPre = reader.result;
                this.config.navigationJsonPre = JSON.parse(this.config.navigationJsonPre);
                this.isNavigationJson = true;
                $("#navigationJson").removeClass("hidden");
                $("#navigationJson").text(file[0].name);
                this.config.configurationFile.navigationJson = this.config.navigationJsonPre;
            } else if(key === "unavailable") {
                this.config.blocksUnavailableJsonPre = reader.result;
                this.config.blocksUnavailableJsonPre = JSON.parse(this.config.blocksUnavailableJsonPre);
                this.isUnavailableJson = true;
                $("#navigationJson").removeClass("hidden");
                $("#navigationJson").text(file[0].name);
                this.config.configurationFile.blocksUnavailable = this.config.blocksUnavailableJsonPre;
            } else if(key === "tabs") {
                this.config.tabOptionsJsonPre = reader.result;
                this.config.tabOptionsJsonPre = JSON.parse(this.config.tabOptionsJsonPre);
                this.isTabsJson = true;
                $("#tabsJson").removeClass("hidden");
                $("#tabsJson").text(file[0].name);
                this.config.configurationFile.tabOptionsJson = this.config.tabOptionsJsonPre;
            }
        }
    }

    removeFile(key) {
        if(key === "filtering") {
            this.inputFilt.nativeElement.value = null;
            this.isFilteringJson = false;
            $("#filteringJson").addClass("hidden");
            $("#filteringJson").text("");

        } else if(key === "navigation") {
            this.inputNavigation.nativeElement.value = null;
            this.isNavigationJson = false;
            $("#navigationJson").addClass("hidden");
            $("#navigationJson").text("");

        } else if(key === "unavailable") {
            this.inputUnavailable.nativeElement.value = null;
            this.isUnavailableJson = false;
            $("#unavailableJson").addClass("hidden");
            $("#unavailableJson").text("");

        }  else if(key === "tabs") {
            this.inputTabs.nativeElement.value = null;
            this.isTabsJson = false;
            $("#tabsJson").addClass("hidden");
            $("#tabsJson").text("");
        }
    }

    //go to preview
    validateData() {
        if($("#filtersCheck").prop('checked')){
            this.filtersCheck = true;
        } else {
            this.filtersCheck = false;
        }
    
        if($("#navigationCheck").prop('checked')){
            this.navigationCheck = true;
        } else {
            this.navigationCheck = false;
        }
    
        if($("#tabsCheck").prop('checked')){
            this.tabsCheck = true;
        } else {
            this.tabsCheck = false;
        }
    
        if($("#searchbarCheck").prop('checked')){
            this.searchbarCheck = true;
        } else {
            this.searchbarCheck = false;
        }

        this.config.configurationFile.filters = this.filtersCheck;
        this.config.configurationFile.navigation = this.navigationCheck;
        this.config.configurationFile.tabs = this.tabsCheck;
        this.config.configurationFile.unavailable = this.unavailableCheck;
        this.config.configurationFile.logo = this.logoCheck;
        this.config.configurationFile.bg = this.bgCheck;
        this.config.configurationFile.favicon = this.faviconCheck;
        this.config.configurationFile.searchbar = this.searchbarCheck;
        // this.config.configurationFile.unavailable = this.unavailableCheck;
        // this.config.configurationFile.tabs = this.tabsCheck;

        this.config.breadcrumbs.push({name:"Preview", state:"common.preview"});
        this.state.go('common.preview');
    }

    ngOnInit() {
        if(!global.getCookie('access_token')) {
            this.state.go('common.login');
        }

        this.filtersCheck = this.config.configurationFile.filters;
        this.navigationCheck = this.config.configurationFile.navigation;
        this.searchbarCheck = this.config.configurationFile.searchbar;
        this.unavailableCheck = this.config.configurationFile.unavailable;
        this.tabsCheck = this.config.configurationFile.tabs;

        if(this.filtersCheck) {
            document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
        }

        if(this.navigationCheck) {
            document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
        }

        if(this.unavailableCheck) {
            document.getElementsByClassName("uploadBtn")[3].classList.remove("disabled");
        }

        if(this.tabsCheck) {
            document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
        }
    }
}
