import { Injectable } from '@angular/core';
declare var DVM: any;
declare var $:any;
import { PopoverService } from './popover.service.js';
import { ConfigurationService } from 'src/app/services/configuration.service.js';


@Injectable({
    providedIn: 'root'
})
export class PreviewDVMService {
    viewer_promise:any;
    viewer_mini_promise: any;
    viewer3d_promise:any;
    viewer: any;
    viewer_mini:any;
    viewer_3d:any;
    viewer_mini_bumper: any;
    minimap_load: any;
    integrationType: string = "dvm";
    venue_id: string = "eu-es-00040-realmadrid";
    map_id: string = "blockmap2d";
    map_id_mini: string = "blockmap2d_minimap";

    filtering: any;
    navigation: any;

    current_view = 'topview';
    popover_timeout = null;

    actualVenue:string;
    actualMap:string;

    seat_styles = {
        av: {
            normal : "#48b319",
            hover : "#48b319",
        },
        sel : {
            normal : "#e22f4a",
            hover : "#e22f4a"
        }
    }

    instance_options = {
        version: "latest",
        plugins:['best_nodes','navigable_minimap'],
        container: "viewer",
        callbacks: {
            click: result => this.onClick(result),
            enter: e => {
                var node = e.nodes[0];
                if(node && node.state !== 'unavailable'){
                    this.popover_timeout = setTimeout(() => {
                        this.popoverCreate(node);
                        this.popover_timeout = null;
                    },100);
                }
            },
            leave: e => {    
                if(this.popover_timeout) {
                    clearTimeout(this.popover_timeout);
                    this.popover_timeout = null;
                }
                else{
                    this.popoverService.popoverDestroyAll();
                } 
            }
        }
    };

    instance_options_mini = {
        container: "blockmap-mini"
    };

    viewer3d_input_options = {
        container: "view3d",
        plugins: ["gallery"]
    };

    windowLoop = windowLoop.bind(this);

    interface = {
        current:"topview",
        seatmap:{
            nodes_selected: []
        },
        topview:{
            nodes_selected: []
        },
        filtering:{
            ref : null,
            nodes : {
                sections: [],
                rows: [],
                seats: []
            }
        },
        navigation:{
            to: "",
            from: ""
        },
        window:{
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            animation: requestAnimationFrame(this.windowLoop)
        }
    };

    changeVenue(venueID:string, mapID:string, minimapID:string) {
        if(this.viewer){
            this.viewer.close();
        }

        if(this.viewer_mini) {
            this.viewer_mini.close();
        }
        
        this.venue_id = venueID;
        this.map_id = mapID;
        this.map_id_mini = minimapID;
    }

    changeJson(filtering, navigation) {
        this.filtering = filtering;
        this.navigation = navigation;
    }

    loadMap(){
        this.viewer_promise = DVM.loadModule("map_viewer", this.instance_options);

        if(this.integrationType === "dvm" && this.config.configurationFileDVM.hasMinimap) {
            this.viewer_mini_promise = DVM.loadModule("map_viewer", this.instance_options_mini);
        }

        this.viewer3d_promise = DVM.loadModule("3d_viewer", this.viewer3d_input_options);
        
        Promise.all([this.viewer_promise, this.viewer_mini_promise, this.viewer3d_promise, this.filtering, this.navigation])
        .then((result) => {
            this.viewer = result[0];
            this.viewer_3d = result[2];

            this.filtering = result[3];
            this.navigation = result[4];

            var mini_opts = {
                container: "blockmap-mini",
                //end_load: this.onLoadMinimap
            }

            if(this.integrationType === "dvm") {
                this.viewer_mini = result[1];
            } else {
                this.viewer.navigable_minimap.createNavigableMinimap(mini_opts)
                    .then((m) => {
                        this.viewer_mini_bumper = m;
                        this.viewer_mini_bumper.flags.fixed_aspect_ratio = false;
                    });
            }

            this.dvmBindInterfaceEvents();
            this.dvmSetInitialFlags();
            this.dvmStart();
        })
        .catch((err) => {
            console.error(err);
        });

        this.windowLoop();
    }

    dvmStart(){
        console.log(this.map_id)
        this.viewer.loadMap({ venue_id: this.venue_id, map_id: this.map_id })
            .then(result => this.onLoadD2MSuccess(result))
            .catch(err => this.onLoadD2MError(err));

        if(this.integrationType === "dvm") {
            this.minimap_load = this.viewer_mini.loadMap({ venue_id: this.venue_id, map_id: this.map_id_mini })
                .then(result => this.onLoadMinimapSuccess(result))
                .catch(err => this.onLoadD2MError(err));
        }

        (window as any).viewer = this.viewer;
        
        return null;
    }

    onLoadD2MSuccess(obj) {
        this.viewer.flags.fixed_aspect_ratio = false;
        this.viewer.flags.automatic_selection = false;
        this.dvmSetFullAvailability(obj.instance);
        this.viewer.max_selection = 1;
        this.actualVenue = this.viewer.getVenueId();

        if(this.viewer.getMapId() === this.map_id){
            if(!this.interface.filtering.ref){ this.filteringInit(); }
            this.interfaceSetTopview();
            this.dvmOnLoadTopview(obj);
        } else {
            this.interfaceSetSeatmap();
            this.dvmOnLoadSeatmap(obj);
        }

        this.interfaceHomeBtnHide();
        this.interfaceViewer3DBtnsHide();
    
        this.interfaceBCSetColours();
    
        if(this.interfaceIsMobile()) {
            this.interfaceMoveArrowsHide();
        }
    }

    onLoadD2MError(err) {
        console.log(err);
        /*if(this.interfaceGetCurrent() === 'seatmap'){
            this.dvmLoadViewer3D(this.interface.topview.nodes_selected[0]);
        }*/
    }

    list = []

    onClick(obj) {
        var node = obj.nodes[0];
        if (node) {
            if (node.type === "section" || node.type === "areaprice") {
                if(this.integrationType === 'bumper'){
                    this.viewer.goTo(node.id,this.viewer.min_scaling_factor*9.5,1000);
                }else{
                    this.list.push(this.viewer.getMapId());
                
                    if(this.list.length < 2) {
                        this.interfaceMinimapHide();
                    } else {
                        this.interfaceMinimapShow();
                        this.dvmSelectViewerMini(node.id);
                    }

                    $("#mapstyle-button-id").addClass("d-none");

                    this.viewer.loadMap({
                        venue_id: this.venue_id,
                        map_id: node.id
                    })
                    .then(result => this.onLoadD2MSuccess(result))
                    .catch(err => this.onLoadD2MError(err));
                }
            } else if (node.type === 'general_admission') {
                this.interfaceShowSectionLabel();
                this.current_view = 'view3d';
                this.list.push(this.viewer.getMapId());
                this.dvmLoadViewer3D(node);
            } else if (node.type === "seat" && node.state !== 'unavailable') {
                if(this.config.configurationFileDVM.hasPano) {
                    this.interfaceShowSectionLabel();
                    this.interfaceShowRowLabel();
                    this.interfaceShowSeatLabel();
                    this.interfaceMinimapShow();
                    this.current_view = 'view3d';
                    this.viewer.unselectAll();
                    this.viewer.select(node.id);
                    this.list.push(this.viewer.getMapId());
                    this.dvmLoadViewer3D(node);
                }
            }
        }

        this.popoverService.popoverDestroyAll();
    }

    dvmLoadViewer3D(node) {
        this.interfacesetViewer3D(node);
        this.interface.seatmap.nodes_selected = [node];
        this.interfaceNavigationHide();

        var node3d = { venue_id: this.venue_id, view_id : node.id }
        this.viewer_3d.open();
        this.viewer_3d.loadView3d(node3d).then(result => {
            this.interfaceZoomHide();
            this.interfaceFSBtnShow();
            this.interfaceHomeBtnShow();
            this.breadcrumbsMobileHide();
            if (this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
                this.interfaceGalleryBtnShow();
            }
            this.interfaceTicketsMobileBtnShow();
            this.interfaceSetInitialNavigation();
            this.dvmNavigationUpdatePopup();
            this.dvmSelectViewerMini(node.id);
        });
        this.interfaceMinimapShow();
    }

    dvmSetInitialFlags(){
        this.viewer.flags.fixed_aspect_ratio = false;
        this.viewer.flags.automatic_selection = false;
        //this.viewer_3d.flags.fixed_aspect_ratio = false;

        if (this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
            this.viewer_3d.gallery.flags.show_gallery_button = false;
        }

        if(this.interfaceIsMobile()){
            this.viewer_3d.interface.setLabelOffset(10,4);
        }else{
            this.viewer_3d.interface.setLabelOffset(20,7);
        }

        if(this.viewer_mini) {
            this.viewer_mini.flags.fixed_aspect_ratio = false;
            this.viewer_mini.flags.automatic_selection = false;
        }

        return false;
    }
    
    dvmBindInterfaceEvents(){
        var zoom_in_btn = document.getElementsByClassName("zoom-in-btn")[0];
        var zoom_out_btn = document.getElementsByClassName("zoom-out-btn")[0];
    
        this.viewer.bindInterfaceAction(zoom_in_btn, "zoom-in");
        this.viewer.bindInterfaceAction(zoom_out_btn, "zoom-out");
    
        var move_left_btn = document.getElementById("move-left");
        var move_right_btn = document.getElementById("move-right");
        var move_up_btn = document.getElementById("move-up");
        var move_down_btn = document.getElementById("move-down");
    
        this.viewer.bindInterfaceAction(move_left_btn, "move-left");
        this.viewer.bindInterfaceAction(move_right_btn, "move-right");
        this.viewer.bindInterfaceAction(move_up_btn, "move-up");
        this.viewer.bindInterfaceAction(move_down_btn, "move-down");
    
        return null;
    }
    
    onEnterCB(obj) {
        if(!this.interfaceIsMobile() && obj.nodes[0].state !== 'unavailable'){
            this.popover_timeout = setTimeout(() => {
                this.popoverCreate(obj);
                this.popover_timeout = null;
            },100);
        }
        return null;
    }
    
    onLeaveCB(obj) {
        if(!this.interfaceIsMobile()){
            if(this.popover_timeout) {
                clearTimeout(this.popover_timeout);
                this.popover_timeout = null;
            }
            else{
                this.popoverService.popoverDestroyAll();
            }
        }
        return null;
    }
    
    onZoomCB(obj){
        
    }
    
    onZoomingPanning(obj){
    
    }

    onLoadMinimap(err) {
        if (err) {
            console.error(err);
        }
        console.log("DGFJ")
        
        $(".minimap").removeClass("hidden");
        var size = this.viewer_mini_bumper.limits.bbox_scene;
        this.viewer_mini_bumper.aspect_ratio = size[3] / size[2];
        this.viewer_mini_bumper.setAvailability("section", this.viewer_mini_bumper.getNodesByType("section"));
    
        //need_update = true;
    }
    
    onLoadMinimapSuccess(obj) {
        this.dvmSetFullAvailability(obj.instance);
        this.viewer_mini.flags.panning = false;
        this.viewer_mini.flags.zooming = false;
        this.viewer_mini.flags.max_zoom_on_first_limit = false;
        this.viewer_mini.scaling_factor = this.viewer_mini.min_scaling_factor;
        return Promise.resolve(obj);
    }
    
    dvmHideLabs(){
        var labs = document.getElementsByClassName("mmc-labels");
        if(labs.length>0){
            for(var i = 0;i<labs.length;i++){
                labs[i].classList.add("d-none");
            }
        }
        return null;
    }

    dvmShowLabs(){
        var labs = document.getElementsByClassName("mmc-labels");
        if(labs.length>0){
            for(var i = 0;i<labs.length;i++){
                labs[i].classList.remove("d-none");
            }
        }
        return null;
    }

    dvmLoadSeatmap(node){
        this.interface.topview.nodes_selected = [node];
    
        this.viewer.loadMap({
            venue_id: this.venue_id,
            map_id: node.id
        })
        .then(result => this.onLoadD2MSuccess(result))
        .catch(err => this.onLoadD2MError(err));
    
        if (this.viewer_mini.isLoaded()) {
            this.viewer_mini.unselectAll();
        }

        this.interfaceMinimapShow();
        this.interfaceSetCurrent('seatmap');
        this.dvmSelectViewerMini(node.id);
    }
    
    dvmSelectViewerMini(id){
        if(this.integrationType === "dvm") {
            this.minimap_load.then(()=>{
                this.viewer_mini.unselectAll();
                this.viewer_mini.select(id.split("-")[0]);
                this.viewer_mini.goTo(id.split("-")[0],this.viewer_mini.min_scaling_factor*2.5,1000);
            })
        }
    }
    
    dvmSetBlockmapStyles(){
        var style = this.viewer.getStyles();
        if(style[0].section && style[0].section.selected){
            style[0].section.selected.normal.none.lineWidth = 1;
            style[0].section.selected.normal.none.strokeStyle = "#fff";
            this.viewer.setStyles(style);
        }
        return null;
    }
    
    dvmSeatmapSetStyles(){
        var style = this.viewer.getStyles();
        if(style[0].seat && style[0].seat.available){
            style[0].seat.available.normal.none.fillStyle = this.seat_styles.av.normal;
            style[0].seat.available.hover.none.fillStyle = this.seat_styles.av.hover;
            style[0].seat.selected.normal.none.fillStyle = this.seat_styles.sel.normal;
            style[0].seat.selected.hover.none.fillStyle = this.seat_styles.sel.hover;
            //reset linewidth
            style[0].seat.available.normal.none.opacity = 0.65;
            style[0].seat.selected.hover.none.lineWidth = 0.05;
            style[0].seat.available.hover.none.lineWidth = 0.05;
            this.viewer.setStyles(style);
        }
        return null;
    }
    
    dvmOnLoadTopview(obj){
        this.dvmSetBlockmapStyles();
    
        this.viewer.max_selection = 1;
        this.dvmSetFullAvailability(obj.instance);
        this.viewer.select(this.interface.topview.nodes_selected);
    
        this.filteringTopviewApply();
    
        var snodes = this.viewer.getNodesByState("section","selected");
        if(snodes.length>0){
            this.interfaceSetNodeBC(snodes[0]);
        }
    
        this.interfaceSetCurrent("topview");
        return null;
    }
    
    dvmOnLoadSeatmap(obj){
        this.dvmSetFullAvailability(obj.instance);
        this.filteringSeatmapApply();
        this.dvmSelectBestSeat();
        return null;
    }

    dvmSelectBestSeat(){
        if(this.viewer.best_nodes){
            this.viewer.best_nodes.getBestNodes("seat",1).then((res)=>{
                this.viewer.goTo(res[0].id,65,900).then(()=>{
                    this.viewer.select(res);
                    this.interfaceSetNodeBC(res[0]);
                })
            }).catch((e)=>{
                console.log(e);
            });
        }
    }
    
    dvmToggleFS(){
        this.viewer_3d.toggleFullscreen().then(()=>{
            $(".dvm-v3d-button-fullscreen").addClass("d-none");
            
            if($(".dvm-v3d-main").hasClass("dvm-v3d-main-fullscreen")){
    
                if(this.interfaceIsMobile()){
                    $(".dvm-v3d-bottom-center").append($(".fsclose-btn").removeClass("d-none"));
                }else{
                    $(".dvm-v3d-top-right").append($(".fsclose-btn").removeClass("d-none"));
                }
            }else{
                $(".fsclose-btn").addClass("d-none");
                $(".interface-btns-desktop").append($(".fsclose-btn"));
            }
        });
    }
    
    dvmSetFullAvailability(instance) {
        var node_types = instance.getTypesList();
    
        node_types.forEach((type) => {
            var ntypes = instance.getNodesByType(type);
            if(ntypes.length>0){
                instance.setAvailability(type,ntypes);
            }
        });
        return null;
    }
    
    dvmNavigationUpdatePopup(){
        var nav = this.interfaceGetNavigation();
        if(nav.to){
            this.viewer.getThumbnail((nav.to as any).id, this.venue_id,true).then((imgUrl)=>{
                $("#navigation-popup").attr("style","background-image: url('"+imgUrl+"')");
                $(".navigation-popup-title p").text((nav.to as any).title);
                this.interfaceNavigationShow();
            }).catch((e)=>{
                console.log(e);
            })
        }
    }
    
    dvmViewer3dOnClickNavNode(obj) {
        var nav = obj.navigation;
        if (nav) {
            nav.reset_camera_rotation = false;
            this.viewer_3d.loadView3d(nav)
        }
    }
    
    dvmNavigationGo(){
        var node = this.viewer.getNodesByState("seat","selected")[0];
        this.interfacesetViewer3D(node);
        this.interface.seatmap.nodes_selected = [node];
        this.interfaceNavigationHide();
    
        var nav = this.interfaceGetNavigation();
        var node3d = { venue_id: this.venue_id, view_id: (nav.to as any).id }
        this.viewer_3d.open();
        this.viewer_3d.loadView3d(node3d).then(()=>{
            this.interfaceSetNavigationReverse();
            this.dvmNavigationUpdatePopup();
            this.interfaceZoomHide();
            this.interfaceFSBtnShow();
            this.interfaceHomeBtnShow();
            this.breadcrumbsMobileHide();

            if (this.viewer_3d.gallery.hasGallery()){
                this.interfaceGalleryBtnShow();
            }
            this.interfaceTicketsMobileBtnShow();
    
        });
    }

    filterSectionOnBlockmap(section) {
        if(this.viewer && this.viewer.isLoaded()){
            this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("section","filtered"), "filtered");
            this.viewer.addNodesToGroup(section, "filtered");
            this.viewer.setAvailability("section", section);
        }
    }
    
    locateSeatmap(id) {
        if(this.config.configurationFile.blocksUnavailable) {

		} else {
			for(var i = 0; i < this.config.configurationFile.exceptions.length; i++) {
				if(this.config.configurationFile.exceptions[i] === id) {
					this.viewer.select = id;
                    this.interfaceShowSectionLabel();

				} else {
					this.list.push(this.viewer.getMapId());
                
                    if(this.list.length < 2) {
                        this.interfaceMinimapHide();
                    } else {
                        this.interfaceMinimapShow();
                        this.dvmSelectViewerMini(id);
                    }

                    $("#mapstyle-button-id").addClass("d-none");

                    this.viewer.loadMap({
                        venue_id: this.venue_id,
                        map_id: id
                    })
                    .then(result => this.onLoadD2MSuccess(result))
                    .catch(err => this.onLoadD2MError(err));
				}			
			}
		}
    }

    /* INTERFACE */

    getAllBlocks() {
        if(this.viewer && this.viewer.isLoaded()) {
            return this.viewer.getNodesByType("section")
        }
    }

    interfaceShowSectionLabel() {
        $(".section").removeClass("hidden");
    }
    
    interfaceHideSectionLabel() {
        $(".section").addClass("hidden");
    }
    
    interfaceShowRowLabel() {
        $(".rows").removeClass("hidden");
    }
    
    interfaceHideRowLabel() {
        $(".rows").addClass("hidden");
    }
    
    interfaceShowSeatLabel() {
        $(".seat").removeClass("hidden");
    }
    
    interfaceHideSeatLabel() {
        $(".seat").addClass("hidden");
    }

    interfaceIsMobile(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width()<620) {
            return true;
        } else {
            return false;
        }
    }

    interfaceMoveArrowsHideOnLimits(){
        if(this.viewer.limits) {
            if(this.viewer.limits.left_limit) {
                $("#move-left").addClass("d-none");
            }
            
            if(this.viewer.limits.right_limit) {
                $("#move-right").addClass("d-none");
            }
            
            if(this.viewer.limits.upper_limit) {
                $("#move-up").addClass("d-none");
            }
            
            if(this.viewer.limits.lower_limit) {
                $("#move-down").addClass("d-none");
            }
        }
        return null;
    }

    interfaceMoveArrowsShow(){
        $("#move-up").removeClass("d-none");
        $("#move-down").removeClass("d-none");
        $("#move-left").removeClass("d-none");
        $("#move-right").removeClass("d-none");
        return null;
    }
    interfaceMoveArrowsHide(){
        $("#move-up").addClass("d-none");
        $("#move-down").addClass("d-none");
        $("#move-left").addClass("d-none");
        $("#move-right").addClass("d-none");
        return null;
    }

    interfaceReset(){
        this.interface = {
            current:"topview",
            seatmap:{
                nodes_selected: []
            },
            topview:{
                nodes_selected: []
            },
            filtering:{
                ref : null,
                nodes : {
                    sections: [],
                    rows: [],
                    seats: []
                }
            },
            navigation:{
                to: "",
                from: ""
            },
            window:{
                innerWidth: window.innerWidth,
                innerHeight: window.innerHeight,
                animation: requestAnimationFrame(this.windowLoop)
            }
        }
    }

    interfaceResetFiltering(){
        this.interface.filtering = {
            ref : null,
            nodes : {
                sections: [],
                rows: [],
                seats: []
            }
        }
    }

    interfaceZoomHide(){
        $(".zoom-in-btn").addClass("d-none");
        $(".zoom-out-btn").addClass("d-none");
        return;
    }

    interfaceZoomShow(){
        if(!this.interfaceIsMobile()){
            $(".zoom-in-btn").removeClass("d-none");
            $(".zoom-out-btn").removeClass("d-none");
        }
       return;
    }

    interfaceMoveFSbtn(){
        if(this.interfaceIsMobile()){
            this.viewer_3d.interface.setInterfaceElementPosition("toggle-fullscreen","bottomcenter")
        }
        return;
    }

    interfaceFSBtnShow(){
        $(".fs-btn").removeClass("d-none");
        return null;
    }

    interfaceFSBtnHide(){
        $(".fs-btn").addClass("d-none");
        return null;
    }

    interfaceHomeBtnShow(){
        $(".home-btn").removeClass("d-none");
        return null;
    }
    
    interfaceHomeBtnHide(){
        $(".home-btn").addClass("d-none");
        return null;
    }

    interfaceGalleryArrowsToggle(){
        $(".glide__arrow").removeClass("d-none");
        if(this.interfaceIsMobile()){
            $(".glide__arrow").addClass("d-none");
        }
        return null;
    }

    interfaceSetCurrent(current){
        this.interface.current = current;
        return this.interface;
    }

    interfaceGetCurrent(){
        return this.interface.current;
    }

    interfaceViewer3DBtnsHide(){
        $(".viewer-btn").addClass("d-none");
        return null;
    }

    interfaceBackBtnShow(){
        $(".back-btn").removeClass("d-none");
        return null;
    }
    
    interfaceBackBtnHide(){
        $(".back-btn").addClass("d-none");
        return null;
    }

    interfaceGalleryBtnHide(){
        $(".gallery-btn").addClass("d-none");
        return null;
    }

    interfaceGalleryBtnShow(){
        $(".gallery-btn").removeClass("d-none");

        return null;
    }

    interfaceMinimapHide(){
        $(".minimap").addClass("d-none")
        return null;
    }

    interfaceMinimapShow(){
        if(!this.map_id_mini){
            return;
        }
        $(".minimap").removeClass("d-none")
        return null;
    }

    interfaceBreadcrumbsValueHide(element){
        $("#top-guide "+element).addClass("d-none");
        return null;
    }

    interfaceZoomSetTopview(){
        $(".zoom-btns-interface").addClass("ci-topview");
        $(".zoom-btns-interface").removeClass("ci-seatmap");
        return null;
    }

    interfaceZoomSetSeatmap(){
        $(".zoom-btns-interface").removeClass("ci-topview");
        $(".zoom-btns-interface").addClass("ci-seatmap");
        return null;
    }

    interfaceSetTopview(){
        this.viewer_3d.close();
        this.viewer_3d.reset();
        this.interfaceViewerShow();
        this.interfaceViewer3DHide();
        this.interfaceBackBtnHide();
        this.interfaceMinimapHide();
        this.interfaceZoomSetTopview();
        this.interfaceZoomShow();
        this.interfaceBreadcrumbsValueHide(".section");
        this.interfaceBreadcrumbsValueHide(".rows");
        this.interfaceBreadcrumbsValueHide(".seat");

        this.interfaceHomeBtnHide();
        this.interfaceFSBtnHide();
        this.interfaceGalleryBtnHide();
        this.interfaceNavigationHide();
        this.menuBtnShow();

        if(this.viewer_mini) {
            this.viewer_mini.scaling_factor = this.viewer_mini.min_scaling_factor;
        }

        this.breadcrumbsShowToggle();

        if(this.interfaceIsMobile()) {
            this.interfaceMoveArrowsHide();
            this.viewer.scaling_factor = 2;
        }
        this.interfaceTicketsMobileBtnHide();
        this.popoverService.popoverDestroyAll();
        this.interfaceSetCurrent("topview");

    }

    interfaceViewerShow(){
        $("#viewer").removeClass("d-none");
        return null;
    }

    interfaceViewer3DHide(){
        $("#view3d").addClass("d-none");
        return null;
    }

    interfaceSetSeatmap(){
        this.viewer_3d.reset();
        this.viewer_3d.close();
        this.interfaceViewerShow();
        this.interfaceViewer3DHide();
        this.interfaceViewer3DBtnsHide();
        this.dvmSeatmapSetStyles();
        if(this.integrationType === 'bumper'){
            this.interfaceBackBtnHide();
        }else{
            this.interfaceBackBtnShow();
        }
        this.menuClose();
        this.menuBtnHide();

        this.interfaceMoveFSbtn();
        this.interfaceFSBtnHide();
        this.interfaceHomeBtnHide();
        this.interfaceZoomSetSeatmap();
        this.interfaceZoomShow();
        this.interfaceNavigationHide();
        this.interfaceMinimapShow();
        if(!this.interfaceIsMobile()) {
            this.interfaceHomeBtnHide();
            this.breadcrumbsShowToggle();
        }
        this.interfaceTicketsMobileBtnHide();
        this.interfaceSetCurrent("seatmap");
        return null;
    }

    interfacesetViewer3D(node){
        this.interfaceGalleryArrowsToggle();
        $("#viewer").addClass("d-none")
        $("#view3d").removeClass("d-none");
        this.interfaceSetNodeBC(node);
        this.interfaceSetCurrent('view3d');
        this.menuBtnHide();
        this.interfaceBackBtnShow();
        this.interfaceNavigationShow();
        return null;
    }

    interfaceGoBack(){
        if (this.interfaceGetCurrent() === "seatmap") {
            this.interfaceSetTopview();

            this.viewer.loadMap({
                venue_id: this.venue_id,
                map_id: this.map_id
            })    
            .then(result => this.onLoadD2MSuccess(result))
            .catch(err => this.onLoadD2MError(err));

            this.current_view = 'topview';

        }  else if (this.interfaceGetCurrent() === 'view3d') {
            this.interfaceSetCurrent("seatmap");
            if(this.interface.seatmap.nodes_selected[0].type=='seat'){
                this.interfaceSetSeatmap();
            }else{
                this.interfaceGoBack();
            }
        }
        this.popoverService.popoverDestroyAll();
        
    }

    interfaceGoHome(){
        this.dvmStart();
    }

    interfaceBCSetColours(){
        for(var type in this.filtering_typereferenced){
            var ft = this.filtering_typereferenced[type];
            $("#top-guide").find(".section-lab").removeClass(type);
            $("#top-guide").find(".row-lab").removeClass(type);
            $("#top-guide").find(".seat-lab").removeClass(type);
            for(var i = 0;i < ft.length; i++){
                var section_id = ft[i];
                if(this.interface.topview.nodes_selected.length > 0 && this.interface.topview.nodes_selected[0].id == section_id){
                    $(".bread-crumbs").find(".breadcrumbs-val").addClass(type);
                    $("#top-guide").find(".section-lab").addClass(type);
                    $("#top-guide").find(".row-lab").addClass(type);
                    $("#top-guide").find(".seat-lab").addClass(type);
                }
                    
            }
        }
    }

    interfaceBCResetColours(){
        for(var type in this.filtering_typereferenced){
            $(".bread-crumbs").find(".breadcrumbs-val").removeClass(type);
            $("#top-guide").find(".section-lab").removeClass(type);
            $("#top-guide").find(".row-lab").removeClass(type);
            $("#top-guide").find(".seat-lab").removeClass(type);
        }
        return null;
    }

    interfaceGoViewer(){ 
        this.interfaceGalleryBtnShow();
        this.interface360BtnHide();
        this.interfaceFSBtnShow();
        this.viewer_3d.gallery.toggleCurrentView();
        this.interfaceMinimapShow();
        this.interfaceHomeBtnShow();
        this.interfaceNavigationShow();
    }

    interface360BtnShow(){
        $(".viewer360-btn").removeClass("d-none");
        return null;
    }

    interface360BtnHide(){
        $(".viewer360-btn").addClass("d-none");
        return null;
    }

    interfaceGoGallery(){
        this.interfaceGalleryBtnHide();
        this.interface360BtnShow();

        this.interfaceFSBtnHide();
        this.viewer_3d.gallery.toggleCurrentView();
        this.interfaceNavigationHide();
        this.interfaceHomeBtnHide();
        this.interfaceMinimapHide();
        $(".glide__arrow--right").html("<span class='interface-btn-standard ti-angle-right'></span>");
        $(".glide__arrow--left").html("<span class='interface-btn-standard ti-angle-left'></span>");

        return null;
    }

    interfaceSetNodeBC(obj) {
        console.log(obj)
        $(".rows").addClass("d-none");
        $(".seat").addClass("d-none");
        $(".section").removeClass("d-none");
        //$(".section-val").text(obj.id.split("_")[1]);

        var selected_nodes = this.viewer.getNodesByState("seat","selected");
        if(selected_nodes.length>0){
            var seat_id = selected_nodes[0].id.split("_")[1].split("-");
            $(".rows").removeClass("d-none");
            $(".seat").removeClass("d-none");
            $(".section").removeClass("d-none");
            if(this.interface.filtering.ref && !this.interfaceIsMobile()){
                $("#top-guide").addClass("filtered");
                $(".section-lab").html("<span class='fa-slash sm-d-none'>/</span> Section");
            }
            $(".section-val").text(seat_id[0]);        
            $(".row-val").text(seat_id[1]);
            $(".seat-val").text(seat_id[2]);
        }
    }

    interfaceSetTicketsURL(url){
        //Set link URL
        $(".tickets-link").attr("href",url);
        return null;
    }

    interfaceTicketsMobileBtnHide(){
        $("#interface-btns-mobile .tickets-link").addClass("d-none");
        return null;
    }

    interfaceTicketsMobileBtnShow(){
        $("#interface-btns-mobile .tickets-link").removeClass("d-none");
        return null;
    }

    interfaceNavigationShow(){
        let current_mapid = this.viewer.getMapId();
        if(this.navigation && this.navigation.outdoor[current_mapid]){
            $(".navigation").removeClass("d-none");
        }
    }

    interfaceNavigationHide(){
        $(".navigation").addClass("d-none");
    }

    interfaceSetNavigationReverse(){
        var aux = this.interface.navigation.to;
        this.interface.navigation.to = this.interface.navigation.from;
        this.interface.navigation.from  = aux;
        return this.interface.navigation;
    }

    interfaceGetNavigation(){
        return this.interface.navigation;
    }

    interfaceSetInitialNavigation(){
        let current_mapid = this.viewer.getMapId();
        if(this.navigation && this.navigation.outdoor[current_mapid]){
            let parent = this.navigation.outdoor[current_mapid];
            let outdoor = parent.node;
            if(!outdoor.title){ outdoor.title = "Acceso sala VIP"; }
            if(!parent.title){ parent.title = "Volver al asiento"; }
            this.interface.navigation.to = outdoor;
            (this.interface.navigation.from as any) = {id: current_mapid, title:parent.title};
        }
        return this.interface.navigation;
    }

    /* POPOVER */

    popoverCreate(obj) {
        this.popoverService.popoverDestroyAll();
        if(!this.interface.current){
            return;
        }
        /*Popover OBJ COnf*/
        var template;
        var seatview = this.viewer.getVisibleNodes("seat").length;

        let that = this;
        new Promise(function(success){
            success(that.config.configurationFileDVM);
        }).then(function(m_config){
            $(".popover-ticket-lab").css("color", m_config['popoverLab']);
            $(".popover-ticket-val").css("color", m_config['popoverVal']);
            $(".popover.bg-header").css("background-color", m_config['bgPopover']);
        });

        if(!seatview && obj.type === "section") {
            if($.inArray(obj.id, (this.filtering_typereferenced as any).vip) !== -1){
                template = this.popoverService.popoverGetContentBlockVIP(obj);
            }else{
                template = this.popoverService.popoverGetContentBlock(obj);
            }
        } else if(obj.type === "seat"){
            if($.inArray(obj.id.split("-")[0], (this.filtering_typereferenced as any).vip) !== -1){
                template = this.popoverService.popoverGetContentSeatVIP(obj);
            }else{
                template = this.popoverService.popoverGetContentSeat(obj);
            }
        }
    
        if(template){
            var popover = $("<div class='fpopover row'>"+template+"</div>");
            $("#main-interface").append(popover);
    
            this.popoverService.popoverMove(popover,obj);
            this.viewer.getThumbnail(obj.id, this.venue_id,true).then((imgUrl) => {
                $(".ticket-selected-view-img").html("");
                $(".ticket-selected-view-img").css("background-image", "url("+imgUrl+")");
            }).catch((e) => {
                console.log(e);
            })
        }

        return true;
    }

    /* FILTERING */

    filtering_referenced = {};
    filtering_typereferenced = {};

    addItemRef(item,parentItem){
        item.ref = item.title.replace(/[^a-zA-Z0-9]/g, "").replace(/ /g,'').toLowerCase();
        if(parentItem){
            if (parentItem.ref){
                item.parentref = parentItem.ref;
            }
            if (parentItem.type){
                item.parenttype = parentItem.type;
            }
        }
        this.filtering_referenced[item.ref]=item;
        if (item.type && !this.filtering_typereferenced[item.type]){
            this.filtering_typereferenced[item.type] = [];
        }
        if (item.parenttype && !this.filtering_typereferenced[item.parenttype]){
            this.filtering_typereferenced[item.parenttype] = [];
        }

        if(item.children){
            for(var i =0;i<item.children.length;i++){
                item.children[i] = this.addItemRef(item.children[i],item);
            }
        }else if(item.nodes){
            var nodes_toadd = [];
            nodes_toadd = nodes_toadd.concat(item.nodes.sections);
            nodes_toadd = nodes_toadd.concat(item.nodes.rows);
            nodes_toadd = nodes_toadd.concat(item.nodes.seats);
            if(item.type){
                this.filtering_typereferenced[item.type] = this.filtering_typereferenced[item.type].concat(nodes_toadd);
            }else{
                this.filtering_typereferenced[item.parenttype] = this.filtering_typereferenced[item.parenttype].concat(nodes_toadd);
            }
        }
        
        return item;
    }

    filteringInit(){
        // $("#accordionContainer").children().remove();
        // if(this.filtering.filtering) {
        //     for(var i = 0; i < this.filtering.filtering.length; i++){
        //         this.filteringItemConstruct(this.filtering.filtering[i]);
        //     }
        // }
        // this.menuAddEventListeners();

        // return null;
    }

    filteringItemConstruct(item){
        item = this.addItemRef(item, null);
        if(!item.nodes){
            let dom = $("<div "+
            "class='drop accordionparent "+item.type+" flex spb'"+
            "data-ref='"+item.ref+"'>"+
                "<button class='accordionparent-wrap'>"+
                "<p>"+item.title+"</p>"+
                "<span class='interface-btn-standard-nosize'>"+
                    "<i class='ti-angle-right'></i>"+
                "</span>"+
                "</button>"+
                "<div class='accordion' data-ref='"+item.ref+"'></div>"+
            "</div>");
            $("#accordionContainer").append(dom);
            this.filteringAccordionConstruct(item);
        }else{
            let dom = $("<div "+
                        "class='drop filter "+item.type+" flex spb'>"+
                        "<button "+
                        "data-ref='"+item.ref+"'"+
                        "(click)='filterBy($event)'>"+
                            "<p>"+item.title+"</p>"+
                        "</button>"+
                        "</div>");
            
            $("#accordionContainer").append(dom);
        }
    }

    filteringAccordionConstruct(item){
        var accordion = $(".accordion[data-ref='"+item.ref+"']");
        if(typeof accordion !== null){
            for (var i = 0; i < item.children.length; i++) {
                $(accordion).append(`
                    <div class='filter' (click)='filterBy($event)' data-ref='`+item.children[i].ref+`'>
                        <span class="f-bull `+item.type+`">&bull;</span> `+item.children[i].title+`
                    </div>`);
            }

        }
        return null;
    }

    filteringSeatmapApply(){
        if(this.filtering && !this.filtering.ref){
            return 
        }

        var nodes = this.interface.filtering.nodes;
        var current_section = null;
        if(nodes.seats.length > 0 || nodes.rows.length>0){
            var seatnodes = nodes.seats;
            var allseats = this.viewer.getNodesByType("seat");

            for(var i = 0;i < allseats.length; i++){
                var seat = allseats[i].id;
                var seat_arr = seat.split("-");
                var concat_row = seat_arr[0]+"-"+seat_arr[1];
                current_section = seat_arr[0];
                if(nodes.rows.indexOf(concat_row)>-1){
                    seatnodes = seatnodes.concat(seat);
                }
            }
            var nodes_toset = [];
            for(var i = 0;i<seatnodes.length;i++){
                if (current_section == seatnodes[i].split("-")[0]){
                    nodes_toset = nodes_toset.concat(seatnodes[i]);
                }
            }
            if(nodes_toset.length >0){
                this.viewer.setAvailability("seat",seatnodes);
                this.interface.seatmap.nodes_selected = seatnodes;
            }
        }

    }

    filteringTopviewApply(){
        if(this.interface.filtering.nodes.sections.length >0){
            this.viewer.setAvailability("section",this.interface.filtering.nodes.sections);
        }
        return;
    }

    setBreadcrumbs(){
        var ref = this.interface.filtering.ref;
        let bc_val = "";
        this.interfaceBCResetColours();
        if(ref){
            let current_fil = this.filtering_referenced[ref];
            bc_val = "<span class='breadcrumbs-atitle'>"+this.filtering_referenced[ref].title+"</span>";
            if(current_fil.parentref){
                bc_val = this.filtering_referenced[current_fil.parentref].title+
                "<span class='fa-slash'>/</span>"+
                bc_val;
            }
            var type = current_fil.type;
            if(!type){
                type = current_fil.parenttype;
            }
            $(".bread-crumbs").find(".breadcrumbs-val").addClass(type);
            $(".section-lab").addClass(type);
            $(".row-lab").addClass(type);
            $(".seat-lab").addClass(type);
        }
        $(".breadcrumbs-val").html(bc_val);

    }

    filterBy(element){    
        this.interfaceResetFiltering();
        if(!$(element).hasClass("active")){
            this.removeOldFiltering();
            $(".filter").find("button").removeClass("active");
            var ref = $(element).attr("id");
            $(element).addClass("active");
            console.log(this.filtering_referenced);
            var nodes = this.filtering_referenced[ref].nodes;
            this.viewer.setAvailability("section",nodes.sections);
            this.interface.topview.nodes_selected = nodes.sections;
            this.interface.filtering.nodes = nodes;
            this.interface.filtering.ref = ref;
            $("#top-guide .section").addClass("d-none");
            if(this.interfaceGetCurrent()=='seatmap'){
                this.interfaceGoHome();
            }
        }else{
            $(".filter").find("button").removeClass("active");
            this.removeOldFiltering();
        }
        this.setBreadcrumbs();

        if(this.interfaceIsMobile()){
            this.menuClose();
        }
        
        return null;
    }

    removeOldFiltering(){
        //remove old filtering
        $(".filter").removeClass("active");
        var nodes = this.viewer.getNodesByTag("section","vipFilter");
        nodes = nodes.concat(this.viewer.getNodesByTag("section","regFilter"));
        this.viewer.setAvailability("section",this.viewer.getNodesByType("section"));
        $("#top-guide").removeClass("filtered");
        $(".section-lab").html("Section");

        return null;
    }

    drop = document.getElementsByClassName("drop accordionparent");

    menuToggle(element){
        let action = $(element).attr("data-action");
        if(action === 'open'){
            this.menuOpen();
        }else{
            this.menuClose();
        }
        return null;
    }

    menuOpen(){
        $("#menu").attr("data-status","opened");
        $(".menu-btn").attr("data-action","close");
        if(this.interfaceIsMobile()){
            $(".menu-btn").find("i").removeClass("icon-menu").addClass("ti-angle-down");
        }
        
        return null;
        
    }

    menuClose(){
        $("#menu").attr("data-status","closed");
        $(".menu-btn").attr("data-action","open");
        $(".menu-btn").find("i").removeClass("ti-angle-down").addClass("icon-menu");
        return null;
    }

    menuBtnHide(){
        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .menu-btn").addClass("d-none");
        }else{
            $("#topbar .menu-btn").addClass("d-none");
        }
        return null;
    }

    menuBtnShow(){
        if(this.filtering && this.filtering.filtering && !this.filtering.filtering.length){
            //***** Add hidden class to the btn dom
            //**** to remove the delay on hidden
            this.menuBtnHide();
            return;
        }

        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .menu-btn").removeClass("d-none");
        }else{
            $("#topbar .menu-btn").removeClass("d-none");
        }

        return null;
    }

    breadcrumbsShowToggle(){
        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .bread-crumbs").removeClass("d-none");
            $("#topbar .bread-crumbs").addClass("d-none");
        }else{
            $("#topbar .bread-crumbs").removeClass("d-none");
            $("#interface-btns-mobile .bread-crumbs").addClass("d-none");
        }
        return null;
    }

    breadcrumbsHide(){
        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .bread-crumbs").addClass("d-none");
        }else{
            $("#topbar .bread-crumbs").addClass("d-none");
        }
        return null;
    }

    breadcrumbsMobileHide(){
        $("#interface-btns-mobile .bread-crumbs").addClass("d-none");

        return null;
    }

    menuAddEventListeners(){
        let accordion_btn = $(".accordionparent").find("button");

        for (var i = 0; i < this.drop.length; i++) {
            accordion_btn[i].addEventListener("click", function() {
                var i = this.querySelector("i");
                if(i.classList.contains("ti-angle-right")){
                    i.classList.remove("ti-angle-right");
                    i.classList.add("ti-angle-down");
                }else{
                    i.classList.remove("ti-angle-down");
                    i.classList.add("ti-angle-right");
                }
                var dropItem = $(this).next(".accordion");
                $(this).toggleClass("active");
                if ($(this).hasClass("active")) {
                    $(dropItem).attr("style","height: auto");
                } else {
                    $(dropItem).removeAttr("style");
                };
            });
        }

        return null;
    }

    constructor(private popoverService: PopoverService, private config: ConfigurationService) {

    }
}

function windowLoop() {
    var bh = $(".minisite-container").innerHeight() - $("#header-wrap").innerHeight();
    
    if(document.getElementById("main-interface")) {
        document.getElementById("main-interface").style.height = bh+"px";
    }

    if(document.getElementById("viewer")) {
        document.getElementById("viewer").style.height = bh+"px";
    }

    if(document.getElementById("blockmap-mini")) {
        document.getElementById("blockmap-mini").style.height = 100+"%";
    }

    if(!$("#view3d").hasClass("d-none")) {
        bh = $("#view3d").innerHeight();
        if(document.getElementById("main-interface")) {
            document.getElementById("main-interface").style.height = bh+"px";
        }
    }

    if(typeof this.viewer !== 'undefined' && this.viewer.isLoaded()){
        if(this.interfaceIsMobile()){
            this.dvmHideLabs();
            $("#interface-btns-mobile").removeClass("d-none");
            $(".interface-btns-desktop").addClass("d-none");
        }else{
            $("#interface-btns-mobile").addClass("d-none");
            $(".interface-btns-desktop").removeClass("d-none");
            this.dvmShowLabs();
        }

        if(this.interfaceGetCurrent()!='view3d'){
            this.breadcrumbsShowToggle();
        }

        if(!this.interfaceIsMobile() && this.viewer.scaling_factor > this.viewer.min_scaling_factor && this.interface.current != "view3d") {
            this.interfaceMoveArrowsShow()
        } else {
            this.interfaceMoveArrowsHide()
        }
        this.interfaceMoveArrowsHideOnLimits();

        if(this.integrationType === "bumper") {
            if (this.viewer.scaling_factor > this.viewer.min_scaling_factor * 1.3 && !$("#blockmap").hasClass("d-none")) {
                $(".minimap").removeClass("d-none");
            } else {
                $(".minimap").addClass("d-none");
            }
        }
    }
    
    this.interface.window.animation = requestAnimationFrame(this.windowLoop);
}