import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ConfigurationService } from '../services/configuration.service';
import * as global from '../services/global-functions'


@Component({
  selector: 'app-common',
  templateUrl: './app-common.component.html'
})
export class AppCommonComponent implements OnInit {
  open: boolean;

  // tslint:disable-next-line:max-line-length
  constructor(private config: ConfigurationService,
    private state: StateService) {
    this.open = true;
  }

  ngOnInit() {

  }

  setBreadcrumb() {
    //this.config.breadcrumbs.push({name:"Content", state:"common.content"});
  }

  removeBreadcrumb() {
    this.config.breadcrumbs = [];
  }

  // Open or Close side menu
  onClickBurger() {
    if (this.open) {
      document.getElementById('burguercont').classList.add('burg');
      document.getElementsByClassName('sideMenu').item(0).classList.add('open');
      const listNode = document.getElementsByClassName('menuText');
      for (let i = 0; i < listNode.length; i++) {
        listNode.item(i).classList.add('textOpen');
      }
      const nodeList = document.getElementsByClassName('pos') as HTMLCollectionOf<HTMLElement>;
      for (const node of nodeList as any) {
        node.style.left = '-47.5%';
      }
      const sideMenu = document.getElementsByClassName('sideMenu').item(0) as HTMLElement;
    } else { // close side menu
      document.getElementById('burguercont').classList.remove('burg');
      document.getElementsByClassName('sideMenu').item(0).classList.remove('open');
      const listNode = document.getElementsByClassName('menuText');
      for (let i = 0; i < listNode.length; i++) {
        listNode.item(i).classList.remove('textOpen');
      }
      const nodeList = document.getElementsByClassName('pos') as HTMLCollectionOf<HTMLElement>;
      for (const node of nodeList as any) {
        node.style.left = '-44.5%';
      }
      const sideMenu = document.getElementsByClassName('sideMenu').item(0) as HTMLElement;
    }

    this.open = !this.open;
  }

  onLogout(): void {
    global.deleteCookie('access_token');
    this.state.go('common.login');
  }

}
