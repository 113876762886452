import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from '@uirouter/core';
import * as global from '../../services/global-functions'
import { PreviewDVMService } from '../preview-dvm/preview-dvm.service';
declare var $: any;


@Component({
  selector: 'app-custom-dvm',
  templateUrl: './custom-dvm.component.html',
  styleUrls: ['./custom-dvm.component.scss']
})
export class CustomDVMComponent implements OnInit {
  buyTickets: boolean = this.config.configurationFileDVM.buyTickets;
  
  bgHeader: string = this.config.configurationFileDVM.bgHeader;
  txtHeader: string = this.config.configurationFileDVM.txtHeader;
  bgTopbar: string = this.config.configurationFileDVM.bgTopbar;
  ticketLab: string = this.config.configurationFileDVM.ticketLab;
  ticketVal: string = this.config.configurationFileDVM.ticketVal;
  bgPopover: string = this.config.configurationFileDVM.bgPopover;
  popoverLab: string = this.config.configurationFileDVM.popoverLab;
  popoverVal: string = this.config.configurationFileDVM.popoverVal;
  bgButton: string = this.config.configurationFileDVM.bgButton;
  buttonLab: string = this.config.configurationFileDVM.buttonLab;

  blockAvailable:string = this.config.configurationFileDVM.blockAvailable;
  blockAvailableHover:string = this.config.configurationFileDVM.blockAvailableHover;
  blockUnavailable:string = this.config.configurationFileDVM.blockUnavailable;
  blockSelected:string = this.config.configurationFileDVM.blockSelected;
  blockSelectedHover:string = this.config.configurationFileDVM.blockSelectedHover;

  seatAvailable:string = this.config.configurationFileDVM.seatAvailable;
  seatAvailableHover:string = this.config.configurationFileDVM.seatAvailableHover;
  seatUnavailable:string = this.config.configurationFileDVM.seatUnavailable;
  seatSelected:string = this.config.configurationFileDVM.seatSelected;
  seatSelectedHover:string = this.config.configurationFileDVM.seatSelectedHover;

  validateData() {
    this.config.configurationFileDVM.bgHeader = this.bgHeader;
    this.config.configurationFileDVM.txtHeader = this.txtHeader;
    this.config.configurationFileDVM.bgTopbar = this.bgTopbar;
    this.config.configurationFileDVM.ticketLab = this.ticketLab;
    this.config.configurationFileDVM.ticketVal = this.ticketVal;
    this.config.configurationFileDVM.bgPopover = this.bgPopover;
    this.config.configurationFileDVM.popoverLab = this.popoverLab;
    this.config.configurationFileDVM.popoverVal = this.popoverVal;
    this.config.configurationFileDVM.bgButton = this.bgButton;
    this.config.configurationFileDVM.buttonLab = this.buttonLab;

    this.config.configurationFileDVM.blockAvailable = this.blockAvailable;
    this.config.configurationFileDVM.blockAvailableHover = this.blockAvailableHover;
    this.config.configurationFileDVM.blockUnavailable = this.blockUnavailable;
    this.config.configurationFileDVM.blockSelected = this.blockSelected;
    this.config.configurationFileDVM.blockSelectedHover = this.blockSelectedHover;

    this.config.configurationFileDVM.seatAvailable = this.seatAvailable;
    this.config.configurationFileDVM.seatAvailableHover = this.seatAvailableHover;
    this.config.configurationFileDVM.seatUnavailable = this.seatUnavailable;
    this.config.configurationFileDVM.seatSelected = this.seatSelected;
    this.config.configurationFileDVM.seatSelectedHover = this.seatSelectedHover;

    this.config.breadcrumbs.push({name:"Files", state:"common.filesdvm"});
    this.state.go('common.filesdvm');
  }

  constructor(private config: ConfigurationService, private state: StateService, private dvm: PreviewDVMService) { }

  ngOnInit() {
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    }
  }

}
