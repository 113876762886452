import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { GeneralState, Tk3dService, InteractionMessageProtocol } from 'ngx-tk3d';
import { ConfigurationService } from 'src/app/services/configuration.service';
import * as global from '../../../../services/global-functions';

@Component({
  selector: 'app-minimap-interface',
  templateUrl: './minimap-interface.component.html',
  styleUrls: ['./minimap-interface.component.scss']
})
export class MinimapInterfaceComponent implements AfterViewInit {
  h; w;
  @Input() state: GeneralState;
  @Input() set blockSelected(value: string) {
    if (!value) {
      return;
    }
    if(this.config.configurationFile.hasMinimap) {
      this.moduleRef.setAvailability(true);
      this.moduleRef.setElementAvailable(this.state.blockmap.selected);
    }
  }

  mapMiniId: string;
  moduleRef: any;

  get minimap_visibility() {
    return false;
  }

  ngAfterViewInit(): void {
    if(this.config.configurationFile.hasMinimap) {
      this.mapMiniId = this.config.configurationFile.minimapID;
      this.h = document.getElementById('minimap').offsetHeight;
      this.w = document.getElementById('minimap').offsetWidth;
  
      const config = {
        module: 'map',
        container: 'area-map-mini',
        plugins: ['SelectionPlugin'],
        config: { // config como siempre
  
        }
      };
  
      this.moduleRef = this.tk3d['tk3d']['loadModule'](config);
      this.moduleRef.loadMap(this.mapMiniId);
      this.moduleRef['setAspectRatio'](this.h / this.w);
      this.state.resizeForce = true;
      this.config.minimapModule = this.moduleRef;
      global.showMinimap(this.config.minimapModule);
    }
  }

  constructor(private tk3d: Tk3dService, private config: ConfigurationService) { 
  }

  onClick() {
    const message: InteractionMessageProtocol = {
      event: 'click',
      emmitterSize: 'mini',
      emitter: 'blockmap',
      // element: elementClicked as TK3dElement
    };
    this.tk3d.handle(message);
    document.getElementById('minimap').classList.add('hidden');
    // $('#minimap').addClass('hidden');
  }
}
