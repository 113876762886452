import {Injectable} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as globals from '../../services/global-functions';
declare var $:any;
import { ConfigurationService } from '../../services/configuration.service';

@Injectable()
export class BlockmapMiniClickAppHandler extends Handler {

    constructor(private minisite_config: ConfigurationService) {
        super();
        this.handling['blockmap'] = ['click'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if (message.emmitterSize !== 'mini') {
            return;
        }
        if(!globals.isMobile()) {
            status.blockmap.moduleRef['setZoom'](1);
            status.blockmap.moduleRef['setMaxZoom'](6);
            status.blockmap.moduleRef['setMinZoom'](1);
            status.blockmap.moduleRef['enableZoom']();
        }

        $(".back-btn").addClass("hidden");
        $(".ticket-selected").addClass("hidden");
        status.blockmap.moduleRef.setAvailability(false);

        if(this.minisite_config.configurationFile.blocksUnavailable) {
            status.blockmap.moduleRef.setElementUnavailable(this.minisite_config.configurationFile.blocksUnavailable);
        }

        status.blockmap.size = 'large';
        status.seatmap.size = 'large';
        status.blockmap.moduleRef['setMaxZoom'](6);
        status.blockmap.moduleRef['setMinZoom'](1);
        status.seatmap.visible = false;
        status.view3d.visible = false;
        status.resizeForce = true;
        globals.hideMinimap();
    }
}
