import { Injectable } from '@angular/core';
import { BreadcrumbModel } from './breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  get breadcrumbs(): BreadcrumbModel[] {
    return this._breadcrumbs;
  }

  // tslint:disable-next-line:variable-name
  private _breadcrumbs: BreadcrumbModel[] = [];

  constructor() {
    this.initBreadcrumbs();
  }

  private initBreadcrumbs() {
    this._breadcrumbs.splice(0, this._breadcrumbs.length);
    // this._breadcrumbs.push(this.initialBreadcrumb);
  }

  setPath(breadcrumb) {
    this.initBreadcrumbs();
    this._breadcrumbs.push(breadcrumb);
  }

  addPath(breadcrumb) {
    this._breadcrumbs.push(breadcrumb);
  }

  setHome() {
    this._breadcrumbs.splice(0, this._breadcrumbs.length);
  }

  showBreadcrumbs(): void {
    try {
      const nav = document.querySelector('nav.breadcrumb') as HTMLElement;
      nav.style.display = 'flex';
    } catch (e) {
      console.log(e);
    }
  }

  hideBreadcrumbs(): void {
    try {
      const nav = document.querySelector('nav.breadcrumb') as HTMLElement;
      nav.style.display = 'none';
    } catch (e) {
      console.log(e);
    }
  }
}
