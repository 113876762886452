import { Component, OnInit } from '@angular/core';
import { PreviewDVMService } from '../preview-dvm.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var $:any

@Component({
  selector: 'app-topbar-dvm',
  templateUrl: './topbar-dvm.component.html',
  styleUrls: ['./topbar-dvm.component.scss']
})
export class TopbarDvmComponent implements OnInit {

  constructor(private dvm: PreviewDVMService, private config: ConfigurationService) { }

  filters = this.config.configurationFileDVM.filters;
  tabs = this.config.configurationFileDVM.tabs;

  menuToggle(event) {
    var element = event.currentTarget;
    this.dvm.menuToggle(element);
  }

  get options() {
    var option = [];

    for(var i in this.config.tabOptionsJsonPre) {
      option.push(this.config.tabOptionsJsonPre[i]);
    }

    return option;
  }

  public openOption(option, index) {
    if(!$("#option-button-"+index).hasClass("active")){
        $(".mapstyle-button").removeClass("unactive");
        $(".mapstyle-button").removeClass("active");
        $("#option-button-"+index).addClass("active");
    }
    $(".ticket-selected").addClass("hidden");
    this.config.configurationFileDVM.venueID = option.venueid;
    this.config.configurationFileDVM.mapID = option.mapid;
    this.dvm.changeVenue(option.venueid, option.mapid, option.minimapid);
    this.dvm.loadMap();
  } 

  ngOnInit() {

  }

}
