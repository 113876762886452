import { UIRouter, RootModule } from '@uirouter/angular';
import { Injector } from '@angular/core';
import { states } from './app-routing.states';
import * as global from '../services/global-functions'


export const config: RootModule = {
  states,
  otherwise: {state: 'common.login'},
  initial: {state: 'common.home'},
  useHash: false,
  config: uiRouterConfigFn
};

function requireAuthentication(transition) {
  const $state = transition.router.stateService;

  if(!global.checkCookie("access_token")) {
    return $state.target('common.login');
  }
}

/** UIRouter Config placeholder  **/
export function uiRouterConfigFn(router: UIRouter, injector: Injector) {

  const criteria = {
    to: (state) => {
      let check = true;
      if (state.data) {
        check = !state.data.openAccess;
      }
      return check;
    }
  };

  router.transitionService.onBefore(criteria, requireAuthentication);
//  router.transitionService.onBefore(notAdministratorCriteria, requireAdministrator);
}
