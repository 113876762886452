import { Component, OnInit, TemplateRef } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import * as global from '../services/global-functions'
import { StateService } from '@uirouter/angular';
import { environment } from '@environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(private modalService: BsModalService, private state: StateService, private config: ConfigurationService, private http: HttpClient) { 
        this.minisitesList = []
    }

    minisitesList: any;
    returnedArray: any;
    typeList = {};
    type;

    modalRef: BsModalRef;
    message: string;

    searchInTable() {
        var searchTerm = $("#mySearch").val();
        var searchSplit = searchTerm.replace(/ /g, "'):containsi('");

        $.extend($.expr[':'], {'containsi': (elem, i, match, array) => {
            return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
            }
        });

        $(".results tbody tr").not(":containsi('" + searchSplit + "')").each((e) => {
            for(var i = 0; i < $(".results tbody tr").length; i++) {
                $(".results tbody tr")[i].setAttribute('visible','false');
            }
        });

        $(".results tbody tr:containsi('" + searchSplit + "')").each((e) => {
            for(var i = 0; i < $(".results tbody tr:containsi('" + searchSplit + "')").length; i++) {
                $(".results tbody tr:containsi('" + searchSplit + "')")[i].setAttribute('visible','true');
            }
        });
    }

    pageChanged(event: PageChangedEvent): void {
        const startItem = (event.page - 1) * event.itemsPerPage;
        const endItem = event.page * event.itemsPerPage;
        this.returnedArray = this.minisitesList.slice(startItem, endItem);
    }

    dynamicSort(property) {
        var sortOrder = 1;
    
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
    
        return function (a,b) {
            if(sortOrder == -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }        
        }
    }

    sortOrder: string = "desc";

    orderListBy(property) {
        if(this.sortOrder === "asc") {
            this.minisitesList.sort(this.dynamicSort(property));
            this.sortOrder = "desc";
        } else if(this.sortOrder === "desc") {
            this.minisitesList.sort(this.dynamicSort("-"+property));
            this.sortOrder = "asc";
        }

        this.returnedArray = this.minisitesList.slice(0,40);
    }

    openModal(template: TemplateRef<any>, minisite) {
        var initialState = {name: minisite['name'], venue: minisite['venue'], url: minisite['url']};
        this.modalRef = this.modalService.show(template, {class: 'modal-md', initialState});
        this.modalRef.content = initialState;
    }
    
    confirm(minisite): void {
        this.deleteMinisite(minisite);
        this.modalRef.hide();
    }
    
    decline(): void {
        this.modalRef.hide();
    }

    deleteMinisite(minisite) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': "Bearer " + global.getCookie('access_token')
            })
        };

        this.http.delete(environment.deleteUrl+minisite['url'], httpOptions).subscribe(
            val => {
                this.listMinisites();
            },
            err => {
                console.error(err);
            }
        )
    }

    editMinisite(minisite) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': "Bearer " + global.getCookie('access_token')
            })
        };

        this.http.get(environment.getClientUrl+minisite['url'], httpOptions).subscribe(
        val => {
            for (let [key, value] of Object.entries(val)){
                if(val['flag'] === "TK3D") {
                    this.config.configurationFile[key] = value;
                    this.state.go('common.content');
                } else if(val['flag'] === "DVM") {
                    this.config.configurationFileDVM[key] = value;
                    this.state.go('common.contentdvm');
                }
            }

            if(val['flag'] === "TK3D") {
                this.config.breadcrumbs.push({name:"Content", state:"common.content"});
            } else if(val['flag'] === "DVM") {
                this.config.breadcrumbs.push({name:"Content", state:"common.contentdvm"});
            }
        },
        error => { console.error(error) }
        );
    }

    listMinisites() {
        this.minisitesList = [];
        const httpOptions = {
            headers: new HttpHeaders({
            'Authorization': "Bearer " + global.getCookie('access_token')
            })
        };

        this.http.get(environment.listUrl, httpOptions).subscribe(
            val => {
                for(var i in val) {
                    this.minisitesList.push(val[i]);
                }

                this.returnedArray = this.minisitesList.slice(0,40);
                this.getMinisiteInfo(this.minisitesList)

            },
            error => { console.log(error) }
        );
        
    }

    getMinisiteInfo(list) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': "Bearer " + global.getCookie('access_token')
            })
        }

        this.minisitesList = [];
        for(var j = 0; j < list.length; j++) {
            this.http.get(environment.getClientUrl+list[j], httpOptions).subscribe(
                val => {
                    if(val['integrationType'] === 'dvm') {
                        val['flag'] = "DVM";
                    } else if(val['integrationType'] === 'bumper') {
                        val['flag'] = "BUMPER"
                    } else {
                        val['flag'] = val["flag"]
                    }

                    this.minisitesList.push({name:val['venueName'], url:val['minisiteUrl'],flag:val['flag'], venue:val['venueID'], viewer:val['viewerIntegration']});
                    this.minisitesList.sort(this.dynamicSort("name"));
                    this.returnedArray = this.minisitesList.slice(0,40);
                }, 
                err => {
                    
                }
            )
        }

    }

    ngOnInit() {
        if(!global.getCookie('access_token')) {
            this.state.go('common.login');
        } else {
            this.listMinisites();

            this.config.logo = null;
            this.config.logoPreview = null;
            this.config.background = null;
            this.config.bgPreview = null;
            this.config.favicon = null;
            this.config.favPreview = null;

            this.config.configurationFile = {
                flag: "TK3D",
                minisiteUrl: "",
                mapID: "",
                minimapID: "",
                venueID: "",
                venueName: "",
                viewType: "",
                viewerIntegration:"old",
                exceptions: [],
                ticketsUrl: "",
                hasMinimap: true,
                buyTickets: true,
                filters: false,
                searchbar: true,
                tabs: false,
                unavailable: false,
                navigation: false,
                logo:true,
                bg:true,
                favicon:true,
                popupImg: "",
                bgHeader: "",
                txtHeader: "",
                bgTopbar:"",
                ticketLab: "",
                ticketVal: "",
                bgPopover: "",
                popoverLab: "",
                popoverVal: "",
                bgButton: "",
                buttonLab: "",
                blockAvailable:"",
                blockAvailableHover:"",
                blockUnavailable:"",
                blockSelected:"",
                blockSelectedHover:"",
                blockOpacity:"",
                seatAvailable:"",
                seatAvailableHover:"",
                seatUnavailable:"",
                seatSelected:"",
                seatSelectedHover:"",
                seatOpacity:""
            }

            this.config.configurationFileDVM = {
                flag: "DVM",
                integrationType:"",
                libraryVersion:"",
                viewerIntegration:"",
                minisiteUrl: "",
                mapID: "",
                venueID: "",
                minimapID: "",
                venueName: "",
                viewType: "",
                hasMinimap: true,
                hasPano: true,
                exceptions: [],
                ticketsUrl: "",
                ticketsName: "",
                filters: false,
                navigation: false,
                buyTickets: true,
                tabs: false,
                searchbar: true,
                logo:true,
                bg:true,
                favicon:true,
                navigationPluginCheck:false,
                galleryPluginCheck:false,
                indoorPluginCheck:false,
                popupImg: "",
                bgHeader: "",
                txtHeader: "",
                bgTopbar:"",
                ticketLab: "",
                ticketVal: "",
                bgPopover: "",
                popoverLab: "",
                popoverVal: "",
                bgButton: "",
                buttonLab: "",
                blockAvailable:"",
                blockAvailableHover:"",
                blockUnavailable:"",
                blockSelected:"",
                blockSelectedHover:"",
                seatAvailable:"",
                seatAvailableHover:"",
                seatUnavailable:"",
                seatSelected:"",
                seatSelectedHover:"",
            }
        }
    }
}
