import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as global from '../../services/global-functions'
declare var $:any;


@Injectable()
export class SeatClickAppHandler extends Handler {
  constructor() {
    super();
    this.handling['seatmap'] = ['click'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (message.emmitterSize === 'mini' ||  !status.seatmap.moduleRef['getElementById'](message.element.id).isAvailable()) {
      return;
    }

    status.seatmap['size'] = 'mini';
    status.view3d['size'] = 'large';
    global.selectedDataWrite(message.element.id);
    status.view3d.moduleRef['setContainer']('view3d');
    status.view3d.moduleRef['load'](message.element.id);
    status.view3d.visible = true;
    global.hideMinimap();
  }
}
