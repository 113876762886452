import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { StateService } from '@uirouter/core';
declare var $: any;
import * as global from '../../services/global-functions'


@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss']
})
export class CustomComponent implements OnInit {
  buyTickets: boolean = this.config.configurationFile.buyTickets;
  
  bgHeader: string = this.config.configurationFile.bgHeader;
  txtHeader: string = this.config.configurationFile.txtHeader;
  bgTopbar: string = this.config.configurationFile.bgTopbar;
  ticketLab: string = this.config.configurationFile.ticketLab;
  ticketVal: string = this.config.configurationFile.ticketVal;
  bgPopover: string = this.config.configurationFile.bgPopover;
  popoverLab: string = this.config.configurationFile.popoverLab;
  popoverVal: string = this.config.configurationFile.popoverVal;
  bgButton: string = this.config.configurationFile.bgButton;
  buttonLab: string = this.config.configurationFile.buttonLab;

  blockAvailable:string = this.config.configurationFile.blockAvailable;
  blockAvailableHover:string = this.config.configurationFile.blockAvailableHover;
  blockUnavailable:string = this.config.configurationFile.blockUnavailable;
  blockSelected:string = this.config.configurationFile.blockSelected;
  blockSelectedHover:string = this.config.configurationFile.blockSelectedHover;
  blockOpacity:string = this.config.configurationFile.blockOpacity;

  seatAvailable:string = this.config.configurationFile.seatAvailable;
  seatAvailableHover:string = this.config.configurationFile.seatAvailableHover;
  seatUnavailable:string = this.config.configurationFile.seatUnavailable;
  seatSelected:string = this.config.configurationFile.seatSelected;
  seatSelectedHover:string = this.config.configurationFile.seatSelectedHover;
  seatOpacity:string = this.config.configurationFile.seatOpacity;

  updateTextInput(val, type) {
    console.log(val)
    if(type === 'block'){
      (document.getElementById('blockOpacity') as any).value = val.target.value; 
      this.blockOpacity = val.target.value;
    } else if(type === 'seat'){
      (document.getElementById('seatOpacity') as any).value = val.target.value; 
      this.seatOpacity = val.target.value;
    }
  }

  validateData() {
    this.config.configurationFile.bgHeader = this.bgHeader;
    this.config.configurationFile.txtHeader = this.txtHeader;
    this.config.configurationFile.bgTopbar = this.bgTopbar;
    this.config.configurationFile.ticketLab = this.ticketLab;
    this.config.configurationFile.ticketVal = this.ticketVal;
    this.config.configurationFile.bgPopover = this.bgPopover;
    this.config.configurationFile.popoverLab = this.popoverLab;
    this.config.configurationFile.popoverVal = this.popoverVal;
    this.config.configurationFile.bgButton = this.bgButton;
    this.config.configurationFile.buttonLab = this.buttonLab;

    this.config.configurationFile.blockAvailable = this.blockAvailable;
    this.config.configurationFile.blockAvailableHover = this.blockAvailableHover;
    this.config.configurationFile.blockUnavailable = this.blockUnavailable;
    this.config.configurationFile.blockSelected = this.blockSelected;
    this.config.configurationFile.blockSelectedHover = this.blockSelectedHover;
    this.config.configurationFile.blockOpacity = this.blockOpacity;

    this.config.configurationFile.seatAvailable = this.seatAvailable;
    this.config.configurationFile.seatAvailableHover = this.seatAvailableHover;
    this.config.configurationFile.seatUnavailable = this.seatUnavailable;
    this.config.configurationFile.seatSelected = this.seatSelected;
    this.config.configurationFile.seatSelectedHover = this.seatSelectedHover;
    this.config.configurationFile.seatOpacity = this.seatOpacity;

    this.config.breadcrumbs.push({name:"Config", state:"common.config"});
    this.state.go('common.config');
  }

  constructor(private config: ConfigurationService, private state: StateService) { }

  ngOnInit() {
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    }
  }
}
