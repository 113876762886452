import { Component, OnInit, Input } from '@angular/core';
import { GeneralState, Tk3dService } from 'ngx-tk3d';
import { ConfigurationService } from 'src/app/services/configuration.service';
import * as global from'../../../services/global-functions';
declare var $:any;

@Component({
  selector: 'app-bottom-interface',
  templateUrl: './bottom-interface.component.html',
  styleUrls: ['./bottom-interface.component.scss']
})
export class BottomInterfaceComponent implements OnInit {

    @Input() state: GeneralState;
    url = this.config.configurationFile.ticketsUrl;
    buyTickets = this.config.configurationFile.buyTickets;

    constructor(private tk3d: Tk3dService, private config: ConfigurationService) { }

    isStep1() {
        return this.state.blockmap.visible && this.state.blockmap.size === 'large';
    }

    isStep2() {
        return this.state.seatmap.visible && this.state.seatmap.size === 'large';
    }

    isStep3 () {
        return this.state.view3d.visible;
    }

    public tk3dBack() {
        if (this.state.view3d.visible) {
            if(this.config.configurationFile.viewType === "section") {
                if(this.config.configurationFile.exceptions && this.config.configurationFile.exceptions.indexOf(this.state.blockmap.selected as any) > -1) {
                    this.tk3d.onSeatMapMiniClick();
                } else {
                    this.tk3d.onBlockMapMiniClick();
                }
            } else {
                if(this.config.configurationFile.exceptions && this.config.configurationFile.exceptions.indexOf(this.state.blockmap.selected as any) > -1) {
                    this.tk3d.onBlockMapMiniClick();
                } else {
                    this.tk3d.onSeatMapMiniClick();
                }
            } 
        } else if(this.state.seatmap.visible){
            //$('#seatmap').height(0);
            this.tk3d.onBlockMapMiniClick();
        }
    }

    public filteringDisplay(event) {
        var element = event.currentTarget;
        console.log(element)
        let action = $(element).attr("data-action");
        console.log(action)
        if(action === 'open'){
            this.menuOpen();
        }else{
            this.menuClose();
        }
        return null;
    }

    menuOpen(){
        $("#menu").attr("data-status","opened");
        $(".filt-btn").attr("data-action","close");
        if(global.isMobile()){
            $(".filt-btn").find("i").removeClass("icon-menu").addClass("icon-arrow-down");
        }
        return null;
        
    }

    menuClose(){
        $("#menu").attr("data-status","closed");
        $(".filt-btn").attr("data-action","open");
        $(".filt-btn").find("i").removeClass("icon-arrow-down").addClass("icon-menu");
        return null;
    }

    toggleFullscreen() {
        this.state.view3d.moduleRef.toggleFullscreen();

        $("#ticketing3dwidget-fullscreen-button0").addClass("d-none");
        
        if(!$("#ticketing3dwidget-fullscreen-button0").hasClass("onfullscreen-tk3d")) {
            $("#ticketing3dwidget0").append($(".fsclose-btn-tk3d").removeClass("d-none"))
        } else {
            $(".fsclose-btn-tk3d").addClass("d-none");
        }
    }

    ngOnInit() {
    }

    ngAfterViewChecked() {
        if(this.state.blockmap.size !== 'large') {
        this.state.blockmap.moduleRef['updateSize']();
        }
    }

}
