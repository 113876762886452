declare var $:any;

export var blockhash = {};

export function showMinimap(minimap) {
    $("#minimap").removeClass("hidden");
    if(minimap) {
        minimap.updateSize();
    }
}

export function hideMinimap() {
    $("#minimap").addClass("hidden");
}

export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    var time = parseInt(exdays) / 3600 / 24;
    d.setTime(d.getTime() + (time * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();

    document.cookie = cname + "=" + cvalue;
    document.cookie = expires;
}
  
export function checkCookie(name) {
    var token = getCookie(name);
    if (token != "") {
      return true;
    } else {
      return false;
    }
}

export function deleteCookie(name) {
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getQueryVariable(variable) {
    var query; var vars; var pair;
    query = window.location.search.substring(1);
    vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return(false);
}

export function isMobile(){
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
    } else {
        return false;
    }
}

export function getMinZoom(mapcontainer_id){
    var container = $("#"+mapcontainer_id)
    var bg = container.find(".svg-img-background");
    var svg_ar;

    if(bg.length > 0){
        var width = parseFloat(bg[0].getAttribute("width"));
        var height = parseFloat(bg[0].getAttribute("height"));
        svg_ar = width / height;
    }else{
        return 1;
    }

    var con_ar = container.width() / container.height();

    return svg_ar / con_ar;
}

export function getSize(mapcontainer_id){
    var bg = $("#" + mapcontainer_id).find(".svg-img-background");
    var ar = 0;

    if(bg.length > 0) {
        var width = parseFloat(bg[0].getAttribute("width"));
        var height = parseFloat(bg[0].getAttribute("height"));
        ar = height / width;
    } else {
        ar = 9/16;
    }

    return ar;
}

export function selectedBlockWrite(mapObj){
    if(mapObj){
        $(".ticket-selected").removeClass("hidden");
        $(".section-lab").text("Section ");
        var section_raw = mapObj.split("_")[1];
        
        $(".section-val").text(section_raw);
    }
    return true;
}

export function selectedDataWrite(mapObj){
    $(".row-lab").text("Row ");
    $(".seat-lab").text("Seat ");
    $(".row-val").text("");
    $(".seat-val").text("");
    if (mapObj){
        var data = mapObj.split("_")[1].split("-");
        if(data.length>1 && data[0].slice(-3) != "INT"){
            $(".section-lab").text("Section ");
            $(".row-val").text(data[1]);
            $(".seat-val").text(data[2]);
        }
    }
}