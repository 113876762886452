import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as global from '../../services/global-functions';
declare var $:any;
import { ConfigurationService } from '../../services/configuration.service';


@Injectable()
export class BlockClickAppHandler extends Handler {
    constructor(private minisite_config: ConfigurationService) {
        super();
        this.handling['blockmap'] = ['click'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if (message.emmitterSize === 'mini' || !status.blockmap.moduleRef['getElementById'](message.element.id).isAvailable()) {
            return;
        }

        if(this.minisite_config.configurationFile.viewType === "seat") {
            if(this.minisite_config.configurationFile.exceptions && this.minisite_config.configurationFile.exceptions.indexOf(message.element.id) > -1){
                status.blockmap['size'] = 'mini';
                status.seatmap['size'] = 'mini';
                status.view3d['size'] = 'large';
                status.view3d.moduleRef['setContainer']('view3d');
                status.view3d.moduleRef['load'](message.element.id);
                status.view3d.visible = true;
            } else {
                status.blockmap['size'] = 'mini';
                status.seatmap.visible = true;
                status.view3d['size'] = 'mini';
            }
        } else if(this.minisite_config.configurationFile.viewType === 'section') {
            if(this.minisite_config.configurationFile.exceptions && this.minisite_config.configurationFile.exceptions.indexOf(message.element.id) > -1){
                status.blockmap['size'] = 'mini';
                status.seatmap.visible = true;
                status.view3d['size'] = 'mini';
            } else {
                status.blockmap['size'] = 'mini';
                status.seatmap['size'] = 'mini';
                status.view3d['size'] = 'large';
                status.view3d.moduleRef['setContainer']('view3d');
                status.view3d.moduleRef['load'](message.element.id);
                status.view3d.visible = true;
            }
            
        }

        status.blockmap.selected = message.element.id;
        global.selectedBlockWrite(message.element.id);
        status.resizeForce = true;
        $(".back-btn").removeClass("hidden");
        status.blockmap.moduleRef.setAvailability(true);
        status.blockmap.moduleRef.setElementAvailable(status.blockmap.selected);
        status.blockmap.moduleRef['disableZoom'](true);
    }
}
