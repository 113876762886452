import { Component, OnInit } from '@angular/core';
import { PreviewDVMService } from '../preview-dvm.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var $:any;


@Component({
  selector: 'app-interface-dvm',
  templateUrl: './interface-dvm.component.html',
  styleUrls: ['./interface-dvm.component.scss']
})
export class InterfaceDvmComponent implements OnInit {
    constructor(private dvm: PreviewDVMService, private config: ConfigurationService) { }
    
    interfaceGoHome() {
        this.dvm.interfaceGoHome();
    }

    interfaceGoBack() {
        this.dvm.interfaceGoBack();
    }

    dvmToggleFS() {
        this.dvm.dvmToggleFS();
    }

    interfaceGoGallery() {
        this.dvm.interfaceGoGallery();
    }

    interfaceGoViewer() {
        this.dvm.interfaceGoViewer();
    }

    filterBy(event) {
        var element = event.currentTarget;
        this.dvm.filterBy(element);
    }

    dvmNavigationGo() {
        this.dvm.dvmNavigationGo();
    }

    showChildrens(event){
        let accordion_btn = event.currentTarget;
        var i = accordion_btn.querySelector("i");

        if(i.classList.contains("ti-angle-right")){
            i.classList.remove("ti-angle-right");
            i.classList.add("ti-angle-down");
        }else{
            i.classList.remove("ti-angle-down");
            i.classList.add("ti-angle-right");
        }
        var dropItem = $(accordion_btn).next(".accordion");
        $(accordion_btn).toggleClass("active");

        if ($(accordion_btn).hasClass("active")) {
            $(dropItem).attr("style","height: auto");
        } else {
            $(dropItem).removeAttr("style");
        };
    }
  
    item = [];
    child = [];

    get items() {
        return this.item;
    }

    get childrens() {
        return this.child;
    }

    filteringItemConstruct(itemRef) {
        this.dvm.addItemRef(itemRef, null);
    }

    ngOnInit() {
        console.log(this.config)
        if(this.config.filteringJsonPre) {
            for(var i = 0; i < this.config.filteringJsonPre.filtering.length; i++) {
                var itemRef = this.config.filteringJsonPre.filtering[i];
                this.filteringItemConstruct(itemRef);
                this.item.push(itemRef);
                if(itemRef.children) {
                    for(var j = 0; j < itemRef.children.length; j++) {
                        this.child.push(itemRef.children[j])
                    }
                }
            }
        }
    }
}
