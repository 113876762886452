import {Injectable} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as globals from '../../services/global-functions';
import { ConfigurationService } from '../../services/configuration.service';
declare var $:any;

@Injectable()
export class SeatmapMiniClickAppHandler extends Handler {

    block; h;

    constructor(private minisite_config: ConfigurationService) {
        super();
        this.handling['seatmap'] = ['click'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if (message.emmitterSize !== 'mini') { return; }
        status.seatmap.size = 'large';

        if(!globals.isMobile()) {
            status.seatmap.moduleRef['setZoom'](1);
            status.seatmap.moduleRef['setMaxZoom'](6);
            status.seatmap.moduleRef['setMinZoom'](1);
            status.seatmap.moduleRef['enableZoom']();
        }

        status.view3d.visible = false;
        status.view3d['size'] = 'mini';
        this.block = status.blockmap.selected;
        message.emitter = 'seatmap';
        status.resizeForce = true;
        status.seatmap.moduleRef.setAvailability(false);

        if(this.minisite_config.configurationFile.seatsUnavailable) {
            status.seatmap.moduleRef.setElementUnavailable(this.minisite_config.configurationFile.seatsUnavailable);
        }

        status.seatmap.visible = true;
        globals.showMinimap(this.minisite_config.minimapModule);

    }
}
